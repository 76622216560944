import stub from '../assets/media/stub.png'

export default class ProductDto {
  id
  provider
  name
  code
  desc
  currency
  unit
  price
  trade_price
  clicks
  measure
  exact_price
  url

  constructor(model) {
    this.id = model.id
    this.provider = model.provider
    this.name = model.name
    this.code = model.code
    this.desc = model.desc
    this.currency = model.currency
    this.unit = model.unit || 'шт'
    this.price = parseInt(model.price) || 0
    this.trade_price = model.trade_price || 0
    this.clicks = model.clicks
    this.exact_price = model.exact_price || 0
    this.url = model.url || '#'
    this._img = model.img
    this.coincidence = model.coincidence
  }

  get measure() {
    return this.unit
  }

  get img() {
    return this._img || stub
  }
}
