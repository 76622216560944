export const SEARCH_OPEN = 'SEARCH_OPEN'
export const SEARCH_LOAD = 'SEARCH_LOAD'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_ERROR = 'SEARCH_ERROR'
export const SEARCH_CLOSE = 'SEARCH_CLOSE'
export const SEARCH_CLEAR = 'SEARCH_CLEAR'
export const SEARCH_SORT_BY_PRICE_ASC = 'SEARCH_SORT_BY_PRICE_ASC'
export const SEARCH_IMG_READY = 'SEARCH_IMG_READY'
export const SEARCH_SET_QUERY = 'SEARCH_SET_QUERY'
export const SEARCH_SET_REPLACE_ID = 'SEARCH_SET_REPLACE_ID'
export const TABLE_ADD_PRODUCT = 'TABLE_ADD_PRODUCT'
export const TABLE_ADD_MANY_PRODUCTS = 'TABLE_ADD_MANY_PRODUCTS'
export const TABLE_DELETE_PRODUCT = 'TABLE_DELETE_PRODUCT'
export const TABLE_SET_CHECKED = 'TABLE_SET_CHECKED'
export const TABLE_CHANGE_PRODUCT = 'TABLE_CHANGE_PRODUCT'
export const TABLE_CALC_TOTAL_PRICE = 'TABLE_CALC_TOTAL_PRICE'
export const TABLE_SAVED = 'TABLE_SAVED'
export const TABLE_SAVE_ERROR = 'TABLE_SAVE_ERROR'
export const TABLE_ERASE_AFTER_SAVE = 'TABLE_ERASE_AFTER_SAVE'
export const TABLE_LOAD = 'TABLE_LOAD'
export const TABLE_LOAD_START = 'TABLE_LOAD_START'
export const TABLE_LOAD_END = 'TABLE_LOAD_END'
export const TABLE_CLEAR = 'TABLE_CLEAR'
export const TABLE_REPLACE = 'TABLE_REPLACE'
export const TABLE_UPDATE = 'TABLE_UPDATE'
export const AUTH_LOAD = 'AUTH_LOAD'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_ERROR_CLEAR = 'AUTH_ERROR_CLEAR'
export const PAYMENT_LOAD = 'PAYMENT_LOAD'
export const PAYMENT_ERROR = 'PAYMENT_ERROR'
export const PAYMENT_CARDS_SUCCESS = 'PAYMENT_CARDS_SUCCESS'
export const PAYMENT_SUB_SUCCESS = 'PAYMENT_SUB_SUCCESS'
export const PAYMENT_SUB_CANCEL = 'PAYMENT_SUB_CANCEL'
export const PAYMENT_CARD_AUTH_START = 'PAYMENT_CARD_AUTH_START'
export const PAYMENT_CARD_AUTH_MESSAGE = 'PAYMENT_CARD_AUTH_MESSAGE'
export const PAYMENT_CARD_AUTH_STOP = 'PAYMENT_CARD_AUTH_STOP'
export const PAYMENT_ERROR_CLEAR = 'PAYMENT_ERROR_CLEAR'
export const SPEC_ADD = 'SPEC_ADD'
export const SPEC_START = 'SPEC_START'
export const SPEC_DONE = 'SPEC_DONE'
export const SPEC_TOGGLE_MODAL = 'SPEC_TOGGLE_MODAL'
export const SPEC_RESET_ISDONE = 'SPEC_RESET_ISDONE'
