import { usePaymentContext } from '../context/payment_context'
import Confirm from './Confirm'

const NewSub = () => {
  const { subscribe, card, removeCard } = usePaymentContext()

  return (
    <>
      <div className="h5 mb-3">Оформление подписки</div>
      <div className="text-lg mb-4">
        Для новых подписчиков первые 3 дня бесплатно, <br /> далее 799 рублей в месяц.
      </div>
      <div className="text-lg mb-5">
        {card.CardFirstSix} ****** {card.CardLastFour} {card.CardType}
      </div>
      <button className="btn mb-3" onClick={subscribe}>
        Оформить подписку
      </button>
      <Confirm onSubmit={removeCard} title="Замена карты" body={`Вы действительно хотите удалить карту?`}>
        <button className="btn">Заменить карту</button>
      </Confirm>
    </>
  )
}

export default NewSub
