import { useEffect, useState } from 'react'
import $server from '../http/'

const useUserTables = () => {
  const [tables, setTables] = useState([])
  const [loading, setLoaading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const getTables = async () => {
    try {
      const tables = await $server.get('/table/list').then((res) => res.data)

      setTables(tables)
      setLoaading(false)
    } catch (error) {
      setErrorMessage(
        JSON.parse(error?.request?.responseText)?.mssage ||
          'Произошла ошибка. Попробуйте позже или обратитесь к администратору.'
      )
      setLoaading(false)
    }
  }

  useEffect(() => {
    setLoaading(true)
    getTables()
  }, [])

  return [tables, loading, errorMessage]
}

export default useUserTables
