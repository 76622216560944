import { useCallback, useState, useEffect } from 'react'
import { usePaymentContext } from '../context/payment_context'
import queryStringFromObject from '../utils/queryStringFromObject'
import AcsWindow from './AcsWindow'
import CardForm from './CardForm'

const formDataInitial = {
  CardCryptogramPacket: '',
  Name: '',
}

const AddCard = () => {
  const [formData, setFormData] = useState(formDataInitial)
  const { authCardData, authCardStart, isCardAuth } = usePaymentContext()

  const handleSubmit = useCallback((CardCryptogramPacket, Name) => {
    setFormData({
      CardCryptogramPacket,
      Name,
    })
  }, [])

  useEffect(() => {
    if (!formData.CardCryptogramPacket) {
      return
    }

    const authCardStop = authCardStart(formData)

    return () => {
      authCardStop()
    }
    // eslint-disable-next-line
  }, [formData])

  if (authCardData?.AcsUrl) {
    return (
      <>
        <span>Сейчас откроется окно для подтверждения 3-D Secure</span>
        <AcsWindow url={`/acs?${queryStringFromObject(authCardData)}`} />
      </>
    )
  }

  if (isCardAuth) {
    return <>Идёт авторизация карты...</>
  }

  return (
    <>
      <div className="h5 mb-3">Для оформления подписки необходимо добавить карту</div>
      <div className="text-lg mb-5">
        Для новых подписчиков первые 3 дня бесплатно, <br /> далее 799 рублей в месяц.
      </div>
      <CardForm onSubmit={handleSubmit} />
      <div className="text mt-3">Мы временно заморозим 11 рублей для подтверждения Вашей карты.</div>
    </>
  )
}

export default AddCard
