import { Modal } from 'react-bootstrap'

const CustomModal = ({ children, show, onHide, title, dialogClassName = 'modal-90w' }) => {
  return (
    <Modal show={show} onHide={onHide} dialogClassName={dialogClassName}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  )
}
export default CustomModal
