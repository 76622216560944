import { useCallback, useEffect, useState } from 'react'
import * as xlsx from 'xlsx'
import { removeAnyNonDigitCharsFromString } from '../utils/helpers'

const articuleStrings = ['Код', 'Артикул', 'Code', 'Articule']
const nameStrings = ['Название', 'Имя', 'Заголовок', 'Name', 'Номенклатура', 'Товары', 'Наименование']
const quanityStrings = ['Кол-во', 'Количество']
const initialColumns = {
  Артикул: null,
  Наименование: null,
  'Кол-во': null,
}

const maxDescriptionSize = 20 //chars

const useTableParser = (files = []) => {
  const [data, setData] = useState(null)
  const [items, setItems] = useState([])
  const [allColumnsWithDesc, setAllColumnsWidthDesc] = useState([])
  const [itemDataColumns, setItemDataColumns] = useState(initialColumns)

  const changeDataColumn = useCallback(
    /**
     * @param {"Артикул" | "Наименование" | "Кол-во"} key
     * @param {string} column
     */
    (key, column) => {
      setItemDataColumns((itemDataColumns) => {
        if (itemDataColumns[key] === undefined) {
          return itemDataColumns
        }

        if (Object.values(itemDataColumns).includes(column)) {
          return itemDataColumns
        }

        return {
          ...itemDataColumns,
          [key]: column,
        }
      })
    },
    []
  )

  // files
  useEffect(() => {
    if (files.length === 0) {
      return
    }

    const workBook = xlsx.read(files[0].content)
    const data = getDataFromWorkBook(workBook)

    setData(data)
  }, [files])

  // data
  useEffect(() => {
    if (data === null) {
      return
    }

    const sheetsCols = getColsFromData(data)
    const colsValues = getColsValues(sheetsCols)
    const codeIntentedColumns = findColumnsWithSubStrings(articuleStrings, colsValues)
    const nameIntentedColumns = findColumnsWithSubStrings(nameStrings, colsValues)
    const quanityIntentedColumns = findColumnsWithSubStrings(quanityStrings, colsValues)

    setItemDataColumns({
      Артикул: codeIntentedColumns[0] || null,
      Наименование: nameIntentedColumns[0] || null,
      'Кол-во': quanityIntentedColumns[0] || null,
    })

    const allColumns = Object.entries(Object.values(sheetsCols)[0])
      .filter(([, data]) => Object.values(data).filter((value) => String(value).length))
      .map(([column, data]) => ({
        column,
        desc: Object.values(data)
          .slice(0, 3)
          .map((cell) =>
            String(cell.v).length > maxDescriptionSize ? String(cell.v).slice(0, maxDescriptionSize) + '…' : cell.v
          )
          .join('; '),
      }))

    setAllColumnsWidthDesc(allColumns)
  }, [data])

  //items
  useEffect(() => {
    if (Object.values(itemDataColumns).some((item) => !item)) {
      return
    }

    const sheetsRows = getRowsFromData(data)
    const items = Object.values(sheetsRows)
      .map((sheet) =>
        Object.values(sheet)
          .filter((row) =>
            Object.values(itemDataColumns).every((column) =>
              Object.values(row)
                .map((cell) => cell.col)
                .includes(column)
            )
          )
          .map((row) =>
            Object.fromEntries(
              Object.entries(itemDataColumns).map(([key, column]) => {
                let value = Object.values(row).find((cell) => cell.col === column).v
                value = typeof value === 'string' ? value.trim() : value
                return [key, value]
              })
            )
          )
      )
      .flat()

    setItems(items)
  }, [itemDataColumns, data])

  return [items, itemDataColumns, allColumnsWithDesc, changeDataColumn]
}

const getDataFromWorkBook = (workBook) => {
  return Object.fromEntries(
    Object.entries(workBook.Sheets)
      .map(([key, sheet]) => [key, Object.fromEntries(Object.entries(sheet).filter(([key]) => !key.includes('!')))])
      .map(([key, sheet]) => [
        key,
        Object.fromEntries(
          Object.entries(sheet).map(([key, cellData]) => {
            const row = removeAnyNonDigitCharsFromString(key)
            const col = key.replace(row, '')

            return [
              key,
              {
                ...cellData,
                row,
                col,
              },
            ]
          })
        ),
      ])
  )
}

const findColumnsWithSubStrings = (strings, columns) => {
  const selectRows = 5
  const results = Object.entries(columns)
    .map(([columnIndex, values]) => [
      columnIndex,
      values
        .slice(0, selectRows)
        .filter((value) => typeof value === 'string')
        .some((value) => strings.some((query) => value.toLowerCase().includes(query.toLowerCase()))),
    ])
    .filter(([, result]) => result)
    .map(([columnIndex]) => columnIndex)
  return results
}

const getRowsFromData = (data) => {
  return Object.fromEntries(
    Object.entries(data).map(([sheetName, sheet]) => [
      sheetName,
      Object.values(sheet).reduce((rows, cell) => {
        const row = rows[cell.row] || {}
        row[`${cell.col}${cell.row}`] = cell
        rows[cell.row] = row
        return rows
      }, {}),
    ])
  )
}

const getColsFromData = (data) => {
  return Object.fromEntries(
    Object.entries(data).map(([sheetName, sheet]) => [
      sheetName,
      Object.values(sheet).reduce((cols, cell) => {
        const col = cols[cell.col] || {}
        col[`${cell.col}${cell.row}`] = cell
        cols[cell.col] = col
        return cols
      }, {}),
    ])
  )
}

const getColsValues = (sheetsCols) => {
  return Object.values(sheetsCols).reduce((colsValues, sheet) => {
    Object.entries(sheet).forEach(([columnName, columnData]) =>
      Object.values(columnData).forEach(
        (cell) => (colsValues[columnName] = [...(colsValues[columnName] ?? []), cell.v])
      )
    )

    return colsValues
  }, {})
}

export default useTableParser
