export const request_timeout = 300

export const notifications = {
  1: {
    title: 'Безопасность',
    message: 'Email адрес подтвержден',
  },

  error: {
    title: 'Ошибка',
    message: 'Ссылка для активации email адреса устарела или не существует',
  },
}
