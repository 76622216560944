/**
 *
 * @param {{[key: string]: boolean}} list
 * @returns {string} class names
 */
const classnames = (list) => {
  let res = ''

  for (const item in list) {
    if (list[item]) res += item + ' '
  }

  return res.slice(0, -1)
}

export default classnames
