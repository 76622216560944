import styled from 'styled-components'
import { usePaymentContext } from '../context/payment_context'
import translateStatusText from '../utils/translateStatusText'
import Confirm from './Confirm'

const SubInfo = () => {
  const { cancelSubscription, subscription, card, subscribe, removeCard } = usePaymentContext()

  return (
    <Wrapper>
      <div className="h3 mb-4">Информация о подписке</div>
      <div className="text_lg mb-2">
        Статус: <span className={subscription.Status}>{translateStatusText(subscription.Status)}</span>
      </div>
      <div className="text_lg mb-2">Дата первого платежа: {new Date(subscription.StartDateIso).toLocaleString()}</div>

      <div className="text_lg mb-2">
        Дата следующего платежа: {new Date(subscription.NextTransactionDateIso).toLocaleString()}
      </div>
      <div className="text_lg mb-2">Ежемесячный платеж: {subscription.Amount}р</div>
      <div className="text_lg mb-2">
        Карта:{' '}
        <span className="text">
          {card.CardFirstSix} ****** {card.CardLastFour} {card.CardType}
        </span>
      </div>
      {subscription.Status === 'Active' && (
        <Confirm
          title="Отмена подписки"
          body={`Вы сможете пользоваться сервисом до ${new Date(
            subscription.NextTransactionDateIso
          ).toLocaleString()}. Вы действительно хотите отменить подписку?`}
          onSubmit={cancelSubscription}
        >
          <button className="btn mt-5">Отменить подписку</button>
        </Confirm>
      )}

      {subscription.Status === 'Cancelled' && (
        <div className="mt-5">
          <Confirm
            title="Возобновление подписки"
            body="Вы действительно хотите возобновить подписку?"
            onSubmit={subscribe}
          >
            <button className="btn mb-3">Возобновить подписку</button>
          </Confirm>
          <Confirm
            title="Удаление карты"
            body={`Вместе с картой пропадёт и подписка. Вы сможете пользоваться сервисом до ${new Date(
              subscription.NextTransactionDateIso
            ).toLocaleString()}`}
            onSubmit={removeCard}
          >
            <button className="btn mb-3">Удалить карту</button>
          </Confirm>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  span.Active {
    color: #28a745;
  }

  span.Cancelled {
    color: #dc3545;
  }
`

export default SubInfo
