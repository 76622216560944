import DownloadLink from 'react-download-link'
import generateFile from '../utils/excel_generator'
import { useTableContext } from '../context/table_context'

const ExcelExportFeature = () => {
  const { collectData } = useTableContext()
  return (
    <DownloadLink
      className="btn"
      filename="report.xlsx"
      label="Экспорт в XLS"
      exportFile={() => generateFile(collectData())}
    />
  )
}

export default ExcelExportFeature
