import React from 'react'
import styled from 'styled-components'

const TableHeadRow = () => {
  return (
    <Wrapper>
      <div className="table__row">
        <div className="table__col text">Фото</div>
        <div className="table__col text">
          Артикул
          <br />
          Наименование
          <br />
          Ссылка
        </div>
        <div className="table__col text">Ед. изм.</div>
        <div className="table__col text">Кол-во</div>
        <div className="table__col text">Цена монтажа</div>
        <div className="table__col text">
          Розничная,
          <br />
          Закуп. цены
        </div>
        <div className="table__col text">Куплено</div>
        <div className="table__col text">Действие</div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default TableHeadRow
