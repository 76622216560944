import { Button, Form } from 'react-bootstrap'
import * as Yup from 'yup'

import { Formik } from 'formik'
import { useCallback, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import styled from 'styled-components'

const cryptogramErrorsMessages = {
  CardNumber_Empty: 'Пустой номер карты',
  CardNumber_Invalid: 'Некорректный номер карты',
  Cvv_Empty: 'Пустой CVV',
  Cvv_Invalid: '	Некорректный CVV',
  ExpDateMonthYear_Empty: 'Пустой год и месяц',
  ExpDateMonthYear_Invalid: 'Некорректный год и месяц',
  ExpDateMonth_Empty: 'Пустой месяц',
  ExpDateMonth_Invalid: 'Некорректный месяц',
  ExpDateYear_Empty: 'Пустой год',
  ExpDateYear_Invalid: 'Некорректный год',
  Name_Empty: 'Пустое имя',
  Name_Invalid: 'Некорректное имя',
  Name_TooLong: 'Слишком длинное имя',
  Name_TooShort: 'Слишком короткое имя',
}

const cryptogramErrorsInitial = {
  cardNumber: '',
  cvv: '',
  expDateMonth: '',
  expDateYear: '',
}

const CardSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .min(16, 'Введите номер карты полностью')
    .max(19, 'Неверный номер карты')
    .required('Введите номер карты'),
  expire: Yup.string()
    .required('Введите срок действия карты')
    .test('Срок действия', 'Введите срок действия карты', (value) =>
      value
        .split('/')
        .map((field) => parseInt(field))
        .every((val) => val)
    ),
  cvv: Yup.string().length(3, 'Введите CVV/CVC код').required('Введите CVV/CVC код'),
  name: Yup.string().min(3, 'Введите имя держателя карты').required('Введите имя держателя карты'),
})

const getCardDataFromValues = (values) => {
  const { cardNumber, cvv, name } = values
  const [expDateMonth, expDateYear] = values.expire.split('/')

  return {
    cardNumber,
    cvv,
    expDateMonth,
    expDateYear,
    name,
  }
}

const getCryptogram = async (data) => {
  const { cp } = window

  const checkout = new cp.Checkout({
    publicId: process.env.REACT_APP_CP_PUBLIC_ID,
  })

  return await checkout.createPaymentCryptogram(data)
}

const CardForm = ({ onSubmit, ...props }) => {
  const [cryptogramErrors, setCryptogramErrors] = useState(cryptogramErrorsInitial)

  const handleSubmit = useCallback(
    async (values) => {
      const data = getCardDataFromValues(values)
      try {
        const cryptogram = await getCryptogram(data)
        setCryptogramErrors(cryptogramErrorsInitial)
        onSubmit(cryptogram, data.name)
      } catch (errors) {
        const cryptogramErrors = Object.fromEntries(
          Object.entries(errors).map(([key, value]) => [key, cryptogramErrorsMessages[value]])
        )
        setCryptogramErrors(cryptogramErrors)
      }
    },
    [onSubmit]
  )

  return (
    <Wrapper {...props}>
      <Formik
        validationSchema={CardSchema}
        onSubmit={handleSubmit}
        initialValues={{
          cardNumber: '',
          expire: '00/00',
          cvv: '',
          name: '',
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue }) => {
          errors = {
            ...cryptogramErrors,
            expire: cryptogramErrors.expDateMonth || cryptogramErrors.expDateYear,
            ...errors,
          }
          return (
            <Form onSubmit={handleSubmit} noValidate>
              <Form.Group className="mb-3" controlId="cardNumber">
                <Form.Label>Номер карты</Form.Label>
                <ReactInputMask
                  type="text"
                  placeholder="Введите номер карты"
                  name="cardNumber"
                  value={values.cardNumber}
                  onChange={(e) => {
                    const value = e.target.value
                    setCryptogramErrors((prev) => ({ ...prev, cardNumber: '' }))
                    setFieldValue('cardNumber', value.replace(/ /g, ''))
                  }}
                  onBlur={handleBlur}
                  isValid={touched.cardNumber && !errors.cardNumber}
                  isInvalid={touched.cardNumber && !!errors.cardNumber}
                  feedback={errors.cardNumber}
                  mask="9999 9999 9999 9999\ 999"
                  maskChar=""
                >
                  {(inputProps) => <Form.Control {...inputProps} />}
                </ReactInputMask>

                <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.cardNumber}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="expire">
                <Form.Label>Срок действия (мм/гг)</Form.Label>
                <ReactInputMask
                  type="text"
                  placeholder="Введите срок действия карты"
                  name="expire"
                  value={values.expire}
                  onChange={(e) => {
                    const value = e.target.value
                    setCryptogramErrors((prev) => ({ ...prev, expDateMonth: '', expDateYear: '' }))
                    setFieldValue('expire', value)
                  }}
                  onBlur={handleBlur}
                  isValid={touched.expire && !errors.expire}
                  isInvalid={touched.expire && !!errors.expire}
                  feedback={errors.expire}
                  mask="99/99"
                  maskChar="0"
                >
                  {(inputProps) => <Form.Control {...inputProps} />}
                </ReactInputMask>

                <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.expire}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="cvv">
                <Form.Label>Введите код безопасности</Form.Label>
                <ReactInputMask
                  type="text"
                  placeholder="Введите CVV/CVC код"
                  name="cvv"
                  value={values.cvv}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.cvv && !errors.cvv && !errors.cvv}
                  isInvalid={touched.cvv && !!errors.cvv && !!errors.cvv}
                  feedback={errors.cvv || errors.cvv}
                  mask="999"
                  maskChar=""
                >
                  {(inputProps) => <Form.Control {...inputProps} />}
                </ReactInputMask>
                <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.cvv || errors.cvv}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Введите имя держателя карты</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Введите имя"
                  name="name"
                  value={values.name}
                  onChange={(e) => {
                    const value = e.target.value
                    setFieldValue('name', value.toUpperCase())
                  }}
                  onBlur={handleBlur}
                  isValid={touched.name && !errors.name}
                  isInvalid={touched.name && !!errors.name}
                  feedback={errors.name}
                />
                <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>
              <Button type="submit">Добавить карту</Button>
            </Form>
          )
        }}
      </Formik>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .valid-feedback {
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }
`

export default CardForm
