import React from 'react'
import styled from 'styled-components'
import { useTableContext } from '../context/table_context'
import { formatPrice } from '../utils/helpers'

const TableFooterRow = () => {
  const { totalPrice, totalInstallationPrice, totalBuyPrice } = useTableContext()

  return (
    <Wrapper>
      <div className="table__row">
        <div className="table__col"></div>
        <div className="table__col">
          <strong>Итого</strong>
        </div>
        <div className="table__col"></div>
        <div className="table__col"></div>
        <div className="table__col">{formatPrice(totalInstallationPrice)}</div>
        <div className="table__col enable-overflow">
          <div>{formatPrice(totalPrice)}</div>
          <div>{formatPrice(totalBuyPrice)}</div>
        </div>
        <div className="table__col"></div>
        <div className="table__col"></div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  strong {
    font-weight: bold;
    font-size: 16px;
  }

  .enable-overflow {
    overflow: visible !important;
  }
`

export default TableFooterRow
