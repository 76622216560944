import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import SignUp from '../components/SignUp'
import { useAuthContext } from '../context/auth_context'

const SignUpPage = () => {
  const { auth } = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (auth) {
      navigate('/profile/Info')
    }

    // eslint-disable-next-line
  }, [auth])
  return (
    <Wrapper>
      <div className="container">
        <h2>Регистрация</h2>
        <SignUp />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: block;
`

export default SignUpPage
