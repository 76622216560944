import React, { useReducer, useContext, useEffect } from 'react'
import searchReducer from '../reducers/search_reducer'
import {
  SEARCH_LOAD,
  SEARCH_SUCCESS,
  SEARCH_ERROR,
  SEARCH_OPEN,
  SEARCH_CLOSE,
  SEARCH_CLEAR,
  SEARCH_IMG_READY,
  SEARCH_SET_QUERY,
  SEARCH_SET_REPLACE_ID,
} from '../actions'
import { request_timeout } from '../utils/constants'
import { parseServerData } from '../utils/helpers'
import $server from '../http'
import getProductImg from '../http/getProductImg'
import { useTableContext } from './table_context'

const initialState = {
  isOpen: false,
  isLoading: false,
  isError: false,
  timer: null,
  foundProducts: [],
  selectedProducts: [],
  query: '',
  replaceId: null,
}

const SearchContext = React.createContext()

export const SearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(searchReducer, initialState)
  const { addProduct } = useTableContext()

  useEffect(() => {
    const timers = state.foundProducts
      .filter((product) => !product._img)
      .map((product, index) =>
        setTimeout(async () => {
          const img = await getProductImg(product.id)

          dispatch({
            type: SEARCH_IMG_READY,
            payload: {
              product,
              img,
            },
          })
        }, index * 300)
      )

    return () => {
      timers.forEach((timer) => clearTimeout(timer))
    }
  }, [state.foundProducts])

  useEffect(() => {
    if (state.query.length <= 3) {
      return
    }
    fetchProducts(state.query)

    // eslint-disable-next-line
  }, [state.query])

  const fetchProducts = (query) => {
    clearTimeout(state.timer)

    try {
      dispatch({
        type: SEARCH_LOAD,
        payload: setTimeout(async () => {
          const request = await $server.post('/search', { query })
          dispatch({ type: SEARCH_SUCCESS, payload: parseServerData(request.data) })
        }, request_timeout),
      })
    } catch (err) {
      dispatch({ type: SEARCH_ERROR })
    }
  }

  const open = () => {
    dispatch({ type: SEARCH_OPEN })
  }

  const close = () => {
    dispatch({ type: SEARCH_CLOSE })
    clear()
  }

  const clear = () => {
    dispatch({ type: SEARCH_CLEAR })
  }

  const selectProduct = (product) => {
    addProduct(product)
  }

  const setQuery = (string) => {
    dispatch({
      type: SEARCH_SET_QUERY,
      payload: string,
    })
  }

  const setReplaceId = (id) => {
    dispatch({
      type: SEARCH_SET_REPLACE_ID,
      payload: id,
    })
  }

  return (
    <SearchContext.Provider
      value={{ ...state, fetchProducts, open, close, clear, selectProduct, setQuery, setReplaceId }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export const useSearchContext = () => {
  return useContext(SearchContext)
}
