import XLSX from 'xlsx'

const generateFile = ({ head, body }) => {
  const wb = XLSX.utils.book_new()
  wb.Props = {
    Title: 'Enprise отчет',
    Subject: 'enprise',
    Author: 'enprise',
    CreatedDate: new Date(),
  }
  wb.SheetNames.push('Отчет')
  const ws = XLSX.utils.aoa_to_sheet([...head, ...body])
  wb.Sheets['Отчет'] = ws
  const merge = [
    { s: { r: 0, c: 4 }, e: { r: 0, c: 5 } },
    { s: { r: 0, c: 6 }, e: { r: 0, c: 7 } },
    { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
    { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
    { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } },
    { s: { r: 0, c: 3 }, e: { r: 1, c: 3 } },
    { s: { r: 0, c: 8 }, e: { r: 1, c: 8 } },
    { s: { r: 0, c: 9 }, e: { r: 1, c: 9 } },
    { s: { r: 2, c: 0 }, e: { r: 2, c: 4 } },
  ]
  ws['!merges'] = merge
  ws['!cols'] = head[0].map((cell, colindex) => ({
    wch: Math.max(...[...head, ...body].map((row) => (row[colindex] + '').length)) + 5,
  }))
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
  const file = new Blob([s2ab(wbout)], { type: 'application/octet-stream' })

  return file
}

const s2ab = (s) => {
  var buf = new ArrayBuffer(s.length)
  var view = new Uint8Array(buf)
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
  return buf
}

export default generateFile
