const data = {
  Cancelled: 'Отменена',
  Active: 'Активна',
  PastDue: 'Просрочена',
  Rejected: 'Отклонена',
  Expired: 'Завершена',
}

const translateStatusText = (status) => {
  return data[status]
}

export default translateStatusText
