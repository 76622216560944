import axios from 'axios'
import React, { useReducer, useContext } from 'react'
import {
  PAYMENT_CARDS_SUCCESS,
  PAYMENT_CARD_AUTH_MESSAGE,
  PAYMENT_CARD_AUTH_START,
  PAYMENT_CARD_AUTH_STOP,
  PAYMENT_ERROR,
  PAYMENT_ERROR_CLEAR,
  PAYMENT_LOAD,
  PAYMENT_SUB_SUCCESS,
} from '../actions'
import $server from '../http'
import paymentReduser from '../reducers/payment_reducer'
import EventSource from 'eventsource'
import { useAuthContext } from './auth_context'
const PaymentContext = React.createContext()

const initialState = {
  card: null,
  subscribtion: null,
  isLoading: false,
  isError: false,
  authCardData: {},
  errors: [],
  errorMessage: null,
  isCardAuth: false,
}

export const PaymentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(paymentReduser, initialState)
  const { checkAuth } = useAuthContext()

  const update = () => {
    updateCard(() => {
      updateSubscription(() => {
        checkAuth()
      })
    })
  }

  const updateCard = (callback = () => {}) => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    dispatch({ type: PAYMENT_LOAD })

    $server
      .get('/user/cards', {
        cancelToken: source.token,
      })
      .then(({ data }) => {
        dispatch({ type: PAYMENT_CARDS_SUCCESS, payload: data })
        callback()
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return
        }

        dispatch({
          type: PAYMENT_ERROR,
          payload: error?.response?.data || {
            errors: [],
            mssage: error,
          },
        })
      })

    return () => {
      source.cancel('Canceled by user')
    }
  }

  const updateSubscription = (callback = () => {}) => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    dispatch({ type: PAYMENT_LOAD })

    $server
      .post('/subscription/get', {
        cancelToken: source.token,
      })
      .then(({ data }) => {
        dispatch({ type: PAYMENT_SUB_SUCCESS, payload: data })
        callback()
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return
        }
        dispatch({
          type: PAYMENT_ERROR,
          payload: error?.response?.data || {
            errors: [],
            mssage: error,
          },
        })
      })
    return () => {
      source.cancel('Canceled by user')
    }
  }

  const cancelSubscription = () => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    dispatch({ type: PAYMENT_LOAD })

    $server
      .post('/subscription/cancel', {
        cancelToken: source.token,
      })
      .then(() => updateSubscription())
      .catch((error) => {
        if (axios.isCancel(error)) {
          return
        }
        dispatch({
          type: PAYMENT_ERROR,
          payload: error?.response?.data || {
            errors: [],
            mssage: error,
          },
        })
      })
    return () => {
      source.cancel('Canceled by user')
    }
  }

  const authCardStart = ({ CardCryptogramPacket, Name }) => {
    dispatch({ type: PAYMENT_CARD_AUTH_START })
    const eventSource = new EventSource(
      `${process.env.REACT_APP_API}/payment/authCard?CardCryptogramPacket=${CardCryptogramPacket}&Name=${Name}`,
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    )

    const handleAuthCardMessage = (message) => {
      const data = JSON.parse(message.data)

      if (data.success && data.end) {
        updateCard()
      }

      if (data.success) {
        dispatch({ type: PAYMENT_CARD_AUTH_MESSAGE, payload: data })
        return
      }

      dispatch({
        type: PAYMENT_ERROR,
        payload: {
          errors: [],
          mssage: data.message,
        },
      })
    }

    const authCardStop = () => {
      eventSource.removeEventListener('message', handleAuthCardMessage)
      eventSource.close()
      dispatch({ type: PAYMENT_CARD_AUTH_STOP })
    }

    eventSource.addEventListener('message', handleAuthCardMessage)

    return authCardStop
  }

  const clearError = () => {
    dispatch({ type: PAYMENT_ERROR_CLEAR })
  }

  const subscribe = async () => {
    dispatch({ type: PAYMENT_LOAD })
    try {
      await $server.post('/subscription/create')
      updateSubscription()
    } catch (error) {
      dispatch({
        type: PAYMENT_ERROR,
        payload: error?.response?.data || {
          errors: [],
          mssage: error,
        },
      })
    }
  }

  const removeCard = async () => {
    dispatch({ type: PAYMENT_LOAD })
    try {
      await $server.post('/payment/removeCard')
      update()
    } catch (error) {
      dispatch({
        type: PAYMENT_ERROR,
        payload: error?.response?.data || {
          errors: [],
          mssage: error,
        },
      })
    }
  }

  return (
    <PaymentContext.Provider
      value={{
        ...state,
        updateCard,
        authCardStart,
        clearError,
        subscribe,
        updateSubscription,
        cancelSubscription,
        update,
        removeCard,
      }}
    >
      {children}
    </PaymentContext.Provider>
  )
}
export const usePaymentContext = () => {
  return useContext(PaymentContext)
}
