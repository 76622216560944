import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Form } from 'react-bootstrap'
import { useAuthContext } from '../context/auth_context'
import { useTableContext } from '../context/table_context'

const SignInSchema = Yup.object().shape({
  email: Yup.string().email('Неверный эмайл').required('Это обязательное поле'),
  password: Yup.string().min(5, 'Минимум 5 символов').max(32, 'Максимум 32 символа').required('Это обязательное поле'),
})

const SingIn = () => {
  const { signInWithPass, isError, errorMessage, clearError, isLoading } = useAuthContext()
  const { clear } = useTableContext()
  const handleSubmit = (values) => {
    signInWithPass(values)
    clear()
  }

  return (
    <Wrapper>
      <Formik
        validationSchema={SignInSchema}
        onSubmit={handleSubmit}
        initialValues={{
          email: '',
          password: '',
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email адрес</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Введите email"
                name="email"
                value={values.email}
                onChange={(e) => {
                  handleChange(e)
                  clearError()
                }}
                onBlur={handleBlur}
                isValid={touched.email && !errors.email && !isError}
                isInvalid={(!!errors.email || isError) && touched.email}
                feedback={errors.email}
              />
              <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.email || errorMessage}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Пароль</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Введите пароль"
                name="password"
                value={values.password}
                onChange={(e) => {
                  handleChange(e)
                  clearError()
                }}
                onBlur={handleBlur}
                isValid={touched.password && !errors.password && !isError}
                isInvalid={(!!errors.password || isError) && touched.password}
                feedback={errors.password}
              />
              <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.password || errorMessage}</Form.Control.Feedback>
            </Form.Group>
            <button className="btn" type="submit" disabled={isLoading}>
              Вход
            </button>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: block;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;

  .btn {
    width: 100%;
    margin-top: 30px;
  }
`

export default SingIn
