import styled from 'styled-components'
import { getDomainFromURL } from '../utils/getDomain'
import { createRef, useCallback, useEffect, useState } from 'react'
import { BiLinkExternal, BiEditAlt } from 'react-icons/bi'

const ProductLink = ({ url, id, onChange }) => {
  const inputRef = createRef()
  const [showMenu, setShowMenu] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const handleClick = useCallback(
    (e) => {
      if (!isEditing && !showMenu) {
        e.preventDefault()
        setShowMenu(true)
      }
    },
    [isEditing, showMenu]
  )

  const openLink = useCallback(() => {
    window.open(url, '_blank', 'noreferrer')
  }, [url])

  const handleEditClick = useCallback(
    (e) => {
      e.preventDefault()
      setIsEditing(true)
      setShowMenu(false)
      inputRef.current.focus()
    },
    [inputRef]
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMenu(false)
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [showMenu])

  return (
    <Wrapper onClick={handleClick}>
      <div className="menu" data-hidden={!showMenu}>
        <div>
          <BiLinkExternal onClick={openLink} title="Открыть ссылку в новой вкладке" />
        </div>
        <div>
          <BiEditAlt onClick={handleEditClick} title="Редактировать ссылку" />
        </div>
      </div>
      <input
        ref={inputRef}
        type="text"
        value={isEditing ? url : getDomainFromURL(url)}
        onChange={(e) => onChange(e.target.value)}
        data-disabled={!isEditing}
        onBlur={() => {
          setIsEditing(false)
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: block;
  cursor: text;

  .menu {
    position: absolute;
    padding: 0.2rem 0.4rem;
    padding-bottom: 0.5rem;
    background-color: #fff;
    display: flex;
    border-radius: 10px;
    margin-left: -2px;
    margin-top: -2px;
    gap: 10px;
    cursor: default;
    width: 200px;
  }

  svg {
    cursor: pointer;
    transition: all 0.25s ease;

    &:hover {
      color: red;
    }
  }

  [data-disabled='true'] {
    pointer-events: none;
  }
  [data-hidden='true'] {
    display: none;
  }
`

export default ProductLink
