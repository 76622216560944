import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import RecoveryForm from '../components/RecoveryForm'

const Recovery = () => {
  const { link } = useParams()
  return (
    <div className="container">
      <Wrapper>
        <h2>Восстановление аккаунта</h2>
        <RecoveryForm link={link} />
      </Wrapper>
    </div>
  )
}

const Wrapper = styled.div`
  display: block;
`

export default Recovery
