import {
  PAYMENT_CARDS_SUCCESS,
  PAYMENT_CARD_AUTH_MESSAGE,
  PAYMENT_CARD_AUTH_START,
  PAYMENT_CARD_AUTH_STOP,
  PAYMENT_ERROR,
  PAYMENT_ERROR_CLEAR,
  PAYMENT_LOAD,
  PAYMENT_SUB_SUCCESS,
} from '../actions'

const paymentReduser = (state, action) => {
  if (action.type === PAYMENT_LOAD) {
    return { ...state, isLoading: true, isError: false }
  }

  if (action.type === PAYMENT_ERROR) {
    const { mssage: errorMessage, errors } = action.payload

    return { ...state, isLoading: false, isError: true, errors, errorMessage }
  }

  if (action.type === PAYMENT_CARDS_SUCCESS) {
    return { ...state, card: action.payload, isLoading: false, isError: false }
  }

  if (action.type === PAYMENT_CARD_AUTH_MESSAGE) {
    return { ...state, authCardData: action.payload }
  }

  if (action.type === PAYMENT_CARD_AUTH_STOP) {
    return { ...state, authCardData: {}, isCardAuth: false }
  }

  if (action.type === PAYMENT_CARD_AUTH_START) {
    return { ...state, isCardAuth: true }
  }

  if (action.type === PAYMENT_ERROR_CLEAR) {
    return { ...state, isError: false }
  }

  if (action.type === PAYMENT_SUB_SUCCESS) {
    return {
      ...state,
      subscription: action.payload,
      isLoading: false,
      isError: false,
    }
  }

  throw new Error(`Действия ${action.type} не существует`)
}

export default paymentReduser
