import React, { useReducer, useContext, useCallback, useMemo } from 'react'
import {
  TABLE_ADD_MANY_PRODUCTS,
  TABLE_ADD_PRODUCT,
  TABLE_CALC_TOTAL_PRICE,
  TABLE_CHANGE_PRODUCT,
  TABLE_CLEAR,
  TABLE_DELETE_PRODUCT,
  TABLE_ERASE_AFTER_SAVE,
  TABLE_LOAD,
  TABLE_LOAD_END,
  TABLE_LOAD_START,
  TABLE_REPLACE,
  TABLE_SAVED,
  TABLE_SAVE_ERROR,
  TABLE_SET_CHECKED,
  TABLE_UPDATE,
} from '../actions'
import $server from '../http'
import tableReducer from '../reducers/table_reducer'
import { getExcelNumberStyle } from '../utils/helpers'
import { getDomainFromURL } from '../utils/getDomain'

const initialState = {
  products: {},
  totalPrice: 0,
  totalBuyPrice: 0,
  totalInstallationPrice: 0,
  name: undefined,
  saveError: false,
  saveSuccess: false,
  saveErrorMessage: undefined,
  totalProducts: 0,
  updateId: -1,
  isLoading: false,
}

const TableContext = React.createContext()

export const TableProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tableReducer, initialState)

  const addProduct = useCallback(
    (product, amount = 100, install_price = 0) => {
      product.amount = amount
      product.install_price = install_price

      dispatch({
        type: TABLE_ADD_PRODUCT,
        payload: { [Date.now()]: product },
      })
      dispatch({ type: TABLE_CALC_TOTAL_PRICE })
    },

    []
  )

  const toggleUpdate = useCallback((id) => {
    dispatch({ type: TABLE_UPDATE, payload: id })
  }, [])

  const addManyProducts = useCallback((products) => {
    dispatch({
      type: TABLE_ADD_MANY_PRODUCTS,
      payload: products,
    })

    dispatch({ type: TABLE_CALC_TOTAL_PRICE })
  }, [])

  const changeProduct = useCallback((id, product) => {
    dispatch({
      type: TABLE_CHANGE_PRODUCT,
      payload: [id, product],
    })

    dispatch({ type: TABLE_CALC_TOTAL_PRICE })
  }, [])

  const deleteProduct = useCallback((id) => {
    dispatch({ type: TABLE_DELETE_PRODUCT, payload: id })
    dispatch({ type: TABLE_CALC_TOTAL_PRICE })
  }, [])

  const setChecked = useCallback((bool, id) => {
    dispatch({
      type: TABLE_SET_CHECKED,
      payload: {
        bool,
        id,
      },
    })

    dispatch({ type: TABLE_CALC_TOTAL_PRICE })
  }, [])

  const getExcelHeader = () => {
    const totalPrice = state.totalPrice
    const totalInstallationPrice = state.totalInstallationPrice
    const summ = totalPrice + totalInstallationPrice
    return [
      ['№', 'Наименование', 'Ед. изм.', 'Кол-во', 'Оборудование', '', 'Монтаж', '', 'Всего', 'Ссылка на товар'],
      ['', '', '', '', 'Цена', 'Стоимость', 'Цена', 'Стоимость'],
      [
        'Итого:',
        '',
        '',
        '',
        '',
        getExcelNumberStyle(totalPrice), // стоимсть всех товаров
        '',
        getExcelNumberStyle(totalInstallationPrice), // стоимость всех установок
        getExcelNumberStyle(summ), // сумма стоимостей всех товаров и установок,
      ],
    ]
  }

  const collectData = () => {
    const body = Object.values(state.products).map((product, index) => {
      const amount = product.amount / 100 || 0
      const totalPrice = product.price * amount || 0
      const installPrice = product.install_price / 100 || 0
      const totalInstallPrice = installPrice * amount || 0
      const total = totalPrice + totalInstallPrice || 0
      return [
        index + 1, // индекс
        product.name, // название
        product.measure || product.unit, // ед. измерения
        getExcelNumberStyle(amount), // кол-во
        getExcelNumberStyle(product.price || 0), // цена
        getExcelNumberStyle(totalPrice), // стоимость
        getExcelNumberStyle(installPrice), // монтаж цена
        getExcelNumberStyle(totalInstallPrice), // монтаж стоимость
        getExcelNumberStyle(total), // сумма
        { l: { Target: product.url }, v: getDomainFromURL(product.url) }, // ссылка
      ]
    })

    return {
      head: getExcelHeader(),
      body,
    }
  }

  const save = async (name) => {
    const data = JSON.stringify(state)
    try {
      const tableData = await $server
        .post('/table/save', {
          name,
          data,
        })
        .then((res) => res.data)

      dispatch({
        type: TABLE_SAVED,
        payload: tableData,
      })

      setTimeout(() => {
        dispatch({
          type: TABLE_ERASE_AFTER_SAVE,
        })
      }, 3000)

      return true
    } catch (error) {
      console.log(error)
      dispatch({
        type: TABLE_SAVE_ERROR,
        payload: {
          message: error,
        },
      })
      return false
    }
  }

  const load = async (id) => {
    try {
      clear()

      dispatch({
        type: TABLE_LOAD_START,
      })

      const data = await $server
        .post('/table/id', {
          id,
        })
        .then((res) => res.data)

      dispatch({
        type: TABLE_LOAD,
        payload: data,
      })

      dispatch({ type: TABLE_CALC_TOTAL_PRICE })
    } catch (error) {
      console.log(error)
    }

    dispatch({
      type: TABLE_LOAD_END,
    })
  }

  const deleteTable = async (id) => {
    try {
      const data = await $server
        .post('/table/delete', {
          id,
        })
        .then((res) => res.data)

      console.log(data)
    } catch (error) {
      console.log(error)
    }
  }

  const clear = () => {
    dispatch({
      type: TABLE_CLEAR,
    })
  }

  const replaceProduct = useCallback(
    (id, product) => {
      dispatch({
        type: TABLE_REPLACE,
        payload: [id, product],
      })
      toggleUpdate(id)
      dispatch({ type: TABLE_CALC_TOTAL_PRICE })
    },
    [toggleUpdate]
  )

  return useMemo(
    () => (
      <TableContext.Provider
        value={{
          ...state,
          deleteProduct,
          collectData,
          save,
          load,
          deleteTable,
          clear,
          setChecked,
          addProduct,
          addManyProducts,
          changeProduct,
          replaceProduct,
        }}
      >
        {children}
      </TableContext.Provider>
    ),
    // eslint-disable-next-line
    [state]
  )
}

export const useTableContext = () => {
  return useContext(TableContext)
}
