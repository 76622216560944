import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

const ErrorPage = () => {
  const { error } = useParams()
  return (
    <div className="container">
      <Wrapper>
        <h2>{error ? error : 'Page Not Found'}</h2>
      </Wrapper>
    </div>
  )
}

const Wrapper = styled.div`
  text-align: center;
`

export default ErrorPage
