import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Form, Col, Toast, ToastContainer } from 'react-bootstrap'
import sendFeedback from '../../utils/sendFeedback.mjs'

const Feedback = () => {
  const [message, setMessage] = useState('')
  const [showToast, setShowToast] = useState(false)

  const handleChange = (e) => {
    setMessage(e.target.value)
  }

  const handleClose = () => {
    setShowToast(false)
  }

  const handleOpen = () => {
    setShowToast(true)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    try {
      await sendFeedback(message)
      setMessage('')
      handleOpen()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Wrapper>
      <ToastContainer position="top-start" className="p-3">
        <Toast show={showToast} delay={500} onClose={handleClose}>
          <Toast.Header>
            <strong className="me-auto">Сообщение отправлено</strong>
          </Toast.Header>
          <Toast.Body>Спасибо за обратную связь!</Toast.Body>
        </Toast>
      </ToastContainer>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="password">
            <Form.Label>Сообщение</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              type="text"
              placeholder="Напишите пожелание или вопрос"
              style={{ resize: 'none', height: '10rem' }}
              value={message}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Row>

        <Row className="mt-5">
          <Form.Group as={Col}>
            <button type="submit" className="btn">
              Отправить
            </button>
          </Form.Group>
        </Row>
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: block;
`

export default Feedback
