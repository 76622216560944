import React from 'react'
import Search from '../components/Search'
import styled from 'styled-components'
import Table from '../components/Table'
import { useAuthContext } from '../context/auth_context'
import { Link } from 'react-router-dom'
import { useTableContext } from '../context/table_context'
import { Spinner } from 'react-bootstrap'

const SearchPage = () => {
  const { auth } = useAuthContext()
  const { name, isLoading } = useTableContext()

  if (!auth) {
    return (
      <Wrapper>
        <div className="container">
          <h2>Поиск позиций</h2>
          <h3>
            Для начала необходимо <Link to="/profile">авторизоваться</Link>
          </h3>
        </div>
      </Wrapper>
    )
  }

  if (auth.user.role === 'member') {
    return (
      <Wrapper>
        <div className="container">
          <h2>Поиск позиций</h2>
          <h3>
            Для начала необходимо <Link to="/profile/Subscription">оформить подписку</Link>
          </h3>
        </div>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <div className="container">
        <h2>Ручной поиск позиций</h2>{' '}
        {isLoading ? (
          <div className="preloader">
            <Spinner animation="border" role="status" className="preloader">
              <span className="visually-hidden">Загрузка....</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Search />
            <div className="name">
              <h2>{name ? `Таблица - ${name}` : `Новая таблица`}</h2>
            </div>
            <Table />
          </>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .preloader {
    max-width: 50px;
    overflow: hidden;
    margin: 0 auto;
    margin-top: 2.5rem;
  }
  .name {
    cursor: default;
    margin: 60px 0 40px;
    position: relative;
    display: flex;
    align-items: center;

    h2 {
      margin: 0;
    }
  }

  a {
    color: inherit;
  }
`

export default SearchPage
