import React, { useReducer, useContext, useEffect } from 'react'
import authReduser from '../reducers/auth_reducer'
import { AUTH_ERROR, AUTH_ERROR_CLEAR, AUTH_LOAD, AUTH_SUCCESS } from '../actions'
import $server from '../http'

const AuthContext = React.createContext()

const initialState = {
  auth: null,
  isLoading: false,
  isError: false,
  errors: [],
  errorMessage: null,
}

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReduser, initialState)

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      checkAuth()
    }
  }, [])

  const checkAuth = async () => {
    dispatch({ type: AUTH_LOAD })
    try {
      const { data: auth } = await $server.get(`/user/refresh`, { withCredentials: true }).catch((error) => {
        throw error.response.data
      })
      localStorage.setItem('accessToken', auth.accessToken)
      dispatch({ type: AUTH_SUCCESS, payload: auth })
    } catch (error) {
      dispatch({ type: AUTH_ERROR, payload: error })
    }
  }

  const createUser = async ({ firstName, secondName, middleName, email, password }) => {
    dispatch({ type: AUTH_LOAD })
    try {
      const { data: auth } = await $server
        .post(`/user/registration`, {
          firstName,
          secondName,
          middleName,
          email,
          password,
        })
        .catch((error) => {
          if (error.response) {
            throw error.response.data
          }

          throw error
        })
      localStorage.setItem('accessToken', auth.accessToken)
      dispatch({ type: AUTH_SUCCESS, payload: auth })
    } catch (error) {
      dispatch({ type: AUTH_ERROR, payload: error })
    }
  }

  const signInWithPass = async ({ email, password }) => {
    dispatch({ type: AUTH_LOAD })
    try {
      const { data: auth } = await $server
        .post(`/user/login`, {
          email,
          password,
        })
        .catch((error) => {
          if (error.response) {
            throw error.response.data
          }

          throw error
        })

      localStorage.setItem('accessToken', auth.accessToken)
      dispatch({ type: AUTH_SUCCESS, payload: auth })
    } catch (error) {
      dispatch({ type: AUTH_ERROR, payload: error })
    }
  }

  const signOutApp = async () => {
    dispatch({ type: AUTH_LOAD })
    try {
      await $server.post(`/user/logout`).catch((error) => {
        if (error.response) {
          throw error.response.data
        }

        throw error
      })
      localStorage.removeItem('accessToken')
      dispatch({ type: AUTH_SUCCESS, payload: null })
    } catch (error) {
      dispatch({ type: AUTH_ERROR, payload: error })
    }
  }

  const recovery = async (link, newPass) => {
    try {
      dispatch({ type: AUTH_LOAD })

      const { data: auth } = await $server
        .post('/user/recovery', {
          link,
          newPass,
        })
        .catch((error) => {
          throw error.response.data
        })

      localStorage.setItem('accessToken', auth.accessToken)
      dispatch({ type: AUTH_SUCCESS, payload: auth })
    } catch (error) {
      console.log(error)
      dispatch({ type: AUTH_ERROR, payload: error })
    }
  }

  const clearError = () => {
    dispatch({ type: AUTH_ERROR_CLEAR })
  }

  const sendRecovery = async (email) => {
    await $server.post('/user/sendRecovery', {
      email,
    })
  }

  const changePassword = async (newPassword) => {
    await $server.post('/user/changePassword', {
      newPassword,
    })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        createUser,
        signInWithPass,
        signOutApp,
        recovery,
        clearError,
        sendRecovery,
        changePassword,
        checkAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  return useContext(AuthContext)
}
