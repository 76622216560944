import * as Yup from 'yup'
import { Modal, Form, ToastContainer, Toast } from 'react-bootstrap'
import { Formik } from 'formik'
import { useTableContext } from '../context/table_context'
import { useCallback, useState } from 'react'

const NameSchema = Yup.object().shape({
  name: Yup.string().required('Это обязательное поле'),
})

const SaveTableFeature = () => {
  const { save, name, saveError, saveErrorMessage, saveSuccess } = useTableContext()
  const [showSaveNameModal, setShowSaveNameModal] = useState(false)

  const handleCloseSaveNameModal = useCallback(() => setShowSaveNameModal(false), [])
  const handleShowSaveNameModal = useCallback(() => setShowSaveNameModal(true), [])

  const handleSubmit = async (values) => {
    await save(values.name)
    handleCloseSaveNameModal()
  }

  return (
    <>
      {/* Уведомление о статусе сохранения */}
      <ToastContainer position="top-start" className="p-3">
        <Toast show={saveError}>
          <Toast.Header>
            <strong className="me-auto">Ошибка сохранения</strong>
          </Toast.Header>
          <Toast.Body>{saveErrorMessage + ''}</Toast.Body>
        </Toast>
      </ToastContainer>
      <ToastContainer position="top-start" className="p-3">
        <Toast show={saveSuccess} delay={500}>
          <Toast.Header>
            <strong className="me-auto">Успех</strong>
          </Toast.Header>
          <Toast.Body>Таблица сохранена</Toast.Body>
        </Toast>
      </ToastContainer>

      {/* Модальное окно и валидация */}
      <Modal show={showSaveNameModal} onHide={handleCloseSaveNameModal}>
        <Modal.Header closeButton>
          <Modal.Title>Введите имя таблицы</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Formik
            validationSchema={NameSchema}
            onSubmit={handleSubmit}
            initialValues={{
              name: name || Date.now().toString(),
            }}
          >
            {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Имя таблицы</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Введите имя таблицы"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.name && !errors.name}
                    isInvalid={!!errors.name && touched.name}
                    feedback={errors.name}
                  />
                  <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>
                <button className="btn" type="submit" style={{ width: '100%' }}>
                  Сохранить
                </button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <button className="btn" onClick={handleShowSaveNameModal}>
        Сохранить расчет
      </button>
    </>
  )
}

export default SaveTableFeature
