import React from 'react'
import styled from 'styled-components'

const StorageTableHeadRow = () => {
  return (
    <Wrapper>
      <div className="table__row">
        <div className="table__col text">Фото</div>
        <div className="table__col text">
          Артикул
          <br />
          Наименование
        </div>
        <div className="table__col text">Ед. изм.</div>
        <div className="table__col text">Куплено</div>
        <div className="table__col text">Установлено</div>
        <div className="table__col text">Остаток</div>
        <div className="table__col text">Выполнено</div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default StorageTableHeadRow
