import { SPEC_ADD, SPEC_DONE, SPEC_RESET_ISDONE, SPEC_START, SPEC_TOGGLE_MODAL } from '../actions'

const specSearchReducer = (state, action) => {
  if (action.type === SPEC_ADD) {
    return {
      ...state,
      spec: action.payload,
    }
  }

  if (action.type === SPEC_START) {
    return {
      ...state,
      isStarted: true,
      isModalOpen: false,
    }
  }

  if (action.type === SPEC_DONE) {
    return {
      ...state,
      isDone: true,
      isStarted: false,
    }
  }

  if (action.type === SPEC_TOGGLE_MODAL) {
    return {
      ...state,
      isModalOpen: action.payload,
    }
  }

  if (action.type === SPEC_RESET_ISDONE) {
    return {
      ...state,
      isDone: false,
    }
  }

  throw new Error(`Действия ${action.type} не существует`)
}

export default specSearchReducer
