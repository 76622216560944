import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const useQuery = () => {
  const { search } = useLocation()

  return useMemo(
    () =>
      Object.fromEntries(
        search
          .substring(1)
          .split('&')
          .map((entry) => entry.split('='))
      ),
    [search]
  )
}

export default useQuery
