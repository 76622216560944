import React, { useState } from 'react'
import { useSearchContext } from '../context/search_context'
import { increaseProductClicks } from '../http/increaseProductClicks'
import { formatPrice } from '../utils/helpers'
import { useTableContext } from '../context/table_context'

const SearchRow = ({ product }) => {
  const { selectProduct, replaceId, clear } = useSearchContext()
  const { replaceProduct } = useTableContext()
  const [clicked, setClicked] = useState(false)
  const handleClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (replaceId) {
      replaceProduct(replaceId, product)
      clear()
    } else {
      setClicked(true)
      selectProduct(product)
    }

    increaseProductClicks(product.id)
  }
  return (
    <div className="dropdown__item" onClick={(e) => handleClick(e)} hidden={clicked}>
      <div className="dropdown__item__photo">
        <img src={product.img} alt="product" className="img"></img>
      </div>
      <div className="dropdown__item__info">
        <div className="text text_space-between">
          {product.code !== undefined ? <p className="text">Код: {product.code}</p> : <p className="text"></p>}
          {product.price !== undefined ? (
            <p className={`text text_bold${product.exact_price ? '' : ' color_red'}`}>
              {formatPrice(product.price)} / {product.measure}
            </p>
          ) : (
            <p className="text text_bold"></p>
          )}
        </div>
        <a href={product.url} className="link" target="_blank" rel="noreferrer">
          {product.name}
        </a>
        <div className="text text_des">{product.desc !== undefined || ''}</div>
      </div>
    </div>
  )
}

export default SearchRow
