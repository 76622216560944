import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTableContext } from '../context/table_context'
import TableFooterRow from './TableFooterRow'
import TableHeadRow from './TableHeadRow'
import TableRow from './TableRow'
import Confirm from './Confirm'
import SaveTableFeature from './SaveTableFeature'
import SpecSearchFeature from './SpecSearchFeature'
import ExcelExportFeature from './ExcelExportFeature'

const Table = () => {
  const { products, name, clear, totalProducts, id, updateId } = useTableContext()

  return useMemo(
    () => (
      <Wrapper>
        <div className="buttons-container">
          <ExcelExportFeature />
          <SaveTableFeature />
          <Confirm title="Очистка таблицы" body="Вы действительно хотите очистить таблицу?" onSubmit={clear}>
            <button className="btn">Очистить таблицу</button>
          </Confirm>
        </div>
        <SpecSearchFeature />
        {!!totalProducts && (
          <div className="table">
            <div className="table__head">
              <TableHeadRow />
            </div>
            <div className="table__body">
              {Object.entries(products).map(([id, product]) => (
                <TableRow key={id} product={product} id={id} />
              ))}
              <TableFooterRow />
            </div>
          </div>
        )}
      </Wrapper>
    ),
    // eslint-disable-next-line
    [totalProducts, name, id, updateId]
  )
}

const Wrapper = styled.div`
  display: block;
  padding-bottom: 5rem;

  .btn {
    text-decoration: none !important;
    color: #fff !important;
    width: auto;
    padding: 0 1rem;
    margin: 5px 25px 0 0 !important;
    font-size: 16px;

    &_add {
      background-color: #f4f4f4;
      border-radius: 10px;
      padding: 1rem 2rem;
      margin: 1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      border: 2px solid #e4e4e4;
      transition: all 0.25s ease;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &:hover {
        border-color: #e43024;

        background-color: #fceae9;
      }
    }
  }

  .buttons-container {
    display: flex;
  }

  .table {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    font-size: 14px;

    &__row {
      display: grid;
      grid-template-columns: 100px 214px 77px 61px 118px 118px 118px 118px 1fr;
      column-gap: 20px;
      padding: 20px;
    }

    &__col {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      font-size: 16px;
      line-height: 19px;
      gap: 5px;
    }

    &__head,
    &__body {
      min-width: 1024px;
    }

    &__body {
      border-radius: 10px;
      border: 2px solid #e4e4e4;
      background-color: #f4f4f4;
    }
  }
`

export default Table
