import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTableContext } from '../context/table_context'
import StorageTableRow from './StorageTableRow'
import StorageTableHeadRow from './StorageTableHeadRow'
import StorageTableFooterRow from './StorageTableFooterRow'
import SaveTableFeature from './SaveTableFeature'

const StorageTable = () => {
  const { products, name, totalProducts, id, updateId } = useTableContext()

  return useMemo(
    () => (
      <Wrapper>
        <div className="buttons-container">
          <SaveTableFeature />
        </div>
        {!!totalProducts && (
          <div className="table">
            <div className="table__head">
              <StorageTableHeadRow />
            </div>
            <div className="table__body">
              {Object.entries(products)
                .filter(([_, product]) => product.bought > 0)
                .map(([id, product]) => (
                  <StorageTableRow key={id} product={product} id={id} />
                ))}
              <StorageTableFooterRow />
            </div>
          </div>
        )}
      </Wrapper>
    ),
    // eslint-disable-next-line
    [totalProducts, name, id, updateId]
  )
}

const Wrapper = styled.div`
  display: block;
  padding-bottom: 5rem;

  .btn {
    text-decoration: none !important;
    color: #fff !important;
    width: auto;
    padding: 0 1rem;
    margin: 5px 25px 0 0 !important;
    font-size: 16px;
  }

  .buttons-container {
    display: flex;
  }

  .table {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    font-size: 14px;

    &__row {
      display: grid;
      grid-template-columns: 100px 214px 1fr 1fr 1fr 1fr 1fr 1fr;
      column-gap: 20px;
      padding: 20px;
    }

    &__col {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      font-size: 16px;
      line-height: 19px;
      gap: 5px;
    }

    &__head,
    &__body {
      min-width: 1024px;
    }

    &__body {
      border-radius: 10px;
      border: 2px solid #e4e4e4;
      background-color: #f4f4f4;
    }
  }
`

export default StorageTable
