import React from 'react'
import { Toast } from 'react-bootstrap'
import { ToastContainer } from 'react-bootstrap'
import { useState } from 'react'

const useNotification = ({ title, message, timeout = 3000 }) => {
  const [show, setShow] = useState(false)

  const displayNotification = () => {
    setShow(true)
  }

  const Notification = () => (
    <ToastContainer position="top-start" className="p-3">
      <Toast show={show} delay={timeout} onClose={() => setShow(false)} autohide>
        <Toast.Header>
          <strong className="me-auto">{title}</strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  )

  return [displayNotification, Notification]
}

export default useNotification
