// import React, { useEffect } from 'react'
import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/styles/global.css'
import Header from './components/Header'
import { Home, Search, Profile, Error, Recovery, Acs, Storage } from './pages'
import { Routes, Route, useLocation } from 'react-router-dom'
import SignUpPage from './pages/SignUpPage'
// import { sendMetrik } from './utils/metriks'

const App = () => {
  const location = useLocation()

  // useEffect(() => {
  //   sendMetrik('hit', window.location.href)
  // }, [location])
  return (
    <>
      {location.pathname === '/' && <div className="landing-bg"></div>}
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<Search />} />
        <Route path="/storage" element={<Storage />} />
        <Route path="/profile">
          <Route path="" element={<Profile />} />
          <Route path=":defaultTab" element={<Profile />} />
        </Route>
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/recovery/:link" element={<Recovery />} />
        <Route path="/error/:error" element={<Error />} />
        <Route path="/acs" element={<Acs />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  )
}

export default App
