import ProductDto from '../dtos/ProductDto.mjs'

export const formatPrice = (number) => {
  return new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
  }).format(number)
}

export const getExcelNumberStyle = (number) => {
  return parseFloat(parseFloat(number).toFixed(2))
}

export const parseServerData = (data) => {
  return data.map((item) => new ProductDto(item))
}

export const removeAnyNonDigitCharsFromString = (string) => {
  return string.replace(/\D/g, '')
}

export const sortProductsAsc = (products) =>
  [...products.filter(({ price }) => price > 0).sort((a, b) => a.price - b.price)].concat(
    products.filter(({ price }) => price === 0)
  )
