import styled from 'styled-components'
import { useSearchContext } from '../context/search_context'

const ReplaceButton = ({ productId, query }) => {
  const { setQuery, setReplaceId } = useSearchContext()

  const handleClick = () => {
    setQuery(query)
    setReplaceId(productId)
  }

  return (
    <Wrapper>
      <button onClick={handleClick}>Заменить</button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: block;
  text-decoration: underline;
  cursor: pointer;

  button {
    outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
    color: inherit;
    font-weight: inherit;
    text-decoration: underline;
  }
`
export default ReplaceButton
