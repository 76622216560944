import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import { useTableContext } from '../context/table_context'
import { removeAnyNonDigitCharsFromString } from '../utils/helpers'
import { CiWarning } from 'react-icons/ci'
import stub from '../assets/media/stub.png'
import classnames from '../utils/classnames'

const StorageTableRow = ({ product, id }) => {
  const { changeProduct, updateId } = useTableContext()

  const [state, dispatch] = useReducer((state, action) => {
    if (action.type === 'update') {
      return {
        ...action.payload,
      }
    }

    return {
      ...state,
      [action.field]: action.payload,
      coincidence: 1,
    }
  }, product)

  const donePercentage = (state.installed || 0) / 100 / (state.bought / 10000)

  const handleBlur = useCallback(() => {
    changeProduct(id, state)
  }, [id, changeProduct, state])

  useEffect(() => {
    dispatch({
      type: 'update',
      payload: product,
    })
  }, [product])

  return useMemo(
    () => (
      <Wrapper>
        <div className={classnames({ table__row: true, done: donePercentage >= 100 })}>
          {/* Фото  */}
          <div className="table__col">
            <div className="product-img">
              {state.coincidence < 0.8 ? (
                <CiWarning size={50} color="#e43024" style={{ display: 'block', margin: '0 auto' }} />
              ) : (
                <img src={product.img || product._img || stub} alt="product" />
              )}
            </div>
          </div>

          {/* info */}
          <div className="table__col">
            <div>
              <input readOnly type="text" value={state.code} data-text placeholder="Введите артикул" />
            </div>
            <div title={state.name}>
              <input readOnly type="text" value={state.name} data-text placeholder="Введите название" />
            </div>
          </div>

          {/* Ед. измерения */}
          <div className="table__col">
            <input readOnly type="text" value={state.unit || state.measure} data-text placeholder="-" />
          </div>

          {/* Куплено */}
          <div className="table__col">
            <NumberFormat
              readOnly
              thousandsGroupStyle="thousand"
              value={state.bought / 100 || 0}
              prefix=""
              decimalSeparator=","
              displayType="input"
              type="text"
              thousandSeparator=" "
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              defaultValue={0}
              placeholder={0}
            />
          </div>

          {/* Установлено */}
          <div className="table__col strong">
            <NumberFormat
              onBlur={handleBlur}
              thousandsGroupStyle="thousand"
              value={state.installed / 100}
              prefix=""
              decimalSeparator=","
              displayType="input"
              type="text"
              thousandSeparator=" "
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              defaultValue={0}
              placeholder={0}
              onChange={(e) => {
                let value = ~~removeAnyNonDigitCharsFromString(e.target.value)

                if (value > state.bought) {
                  value = state.bought
                }
                dispatch({
                  field: 'installed',
                  payload: value,
                })
              }}
            />
          </div>

          {/* Остаток */}
          <div className="table__col">
            <NumberFormat
              readOnly
              thousandsGroupStyle="thousand"
              value={(state.bought - (state.installed || 0)) / 100}
              prefix=""
              decimalSeparator=","
              displayType="input"
              type="text"
              thousandSeparator=" "
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              defaultValue={0}
              placeholder={0}
            />
          </div>

          {/* Выполнено */}
          <div className="table__col">
            <NumberFormat
              readOnly
              thousandsGroupStyle="thousand"
              value={donePercentage}
              prefix=""
              suffix=" %"
              decimalSeparator=","
              displayType="input"
              type="text"
              thousandSeparator=" "
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              defaultValue={0}
              placeholder={0}
            />
          </div>
        </div>
      </Wrapper>
    ),
    // eslint-disable-next-line
    [state, product, updateId]
  )
}

const Wrapper = styled.div`
  border-bottom: 2px solid #e4e4e4;

  .table__row {
    transition: all 0.25s ease;
  }

  .table__row.done {
    opacity: 0.3;
  }
  .table__col.strong input {
    font-weight: 500;
  }
  .action {
    text-decoration: underline;
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  &:nth-child(even) {
    background-color: #fff;
  }
  .pointer {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .checker {
    position: relative;
  }

  .checker:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 20px;
    cursor: pointer;
  }

  .product-img {
    overflow: hidden;

    img {
      max-width: 68px;
      mix-blend-mode: multiply;
      display: block;
      margin: 0 auto;
    }
  }

  input {
    box-sizing: border-box;
    width: 100%;
    display: block;
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: inherit;
    line-height: inherit;
    outline: none;
    font-weight: inherit;
    color: inherit;

    &[data-text] {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`

export default StorageTableRow
