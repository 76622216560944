import {
  SEARCH_LOAD,
  SEARCH_SUCCESS,
  SEARCH_ERROR,
  SEARCH_OPEN,
  SEARCH_CLOSE,
  SEARCH_CLEAR,
  SEARCH_SORT_BY_PRICE_ASC,
  SEARCH_IMG_READY,
  SEARCH_SET_QUERY,
  SEARCH_SET_REPLACE_ID,
} from '../actions'
import { sortProductsAsc } from '../utils/helpers'

const searchReducer = (state, action) => {
  if (action.type === SEARCH_LOAD) {
    return { ...state, isLoading: true, timer: action.payload }
  }

  if (action.type === SEARCH_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      cancelFetch: () => {},
      foundProducts: action.payload,
      isOpen: true,
    }
  }

  if (action.type === SEARCH_ERROR) {
    return { ...state, isLoading: false, isError: true, cancelFetch: () => {}, isOpen: false }
  }

  if (action.type === SEARCH_SORT_BY_PRICE_ASC) {
    return { ...state, foundProducts: sortProductsAsc(state.foundProducts) }
  }

  if (action.type === SEARCH_OPEN) {
    return { ...state, isOpen: true }
  }

  if (action.type === SEARCH_CLOSE) {
    return { ...state, isOpen: false, replaceId: null }
  }

  if (action.type === SEARCH_CLEAR) {
    return { ...state, isOpen: false, foundProducts: [], query: '', replaceId: null }
  }

  if (action.type === SEARCH_IMG_READY) {
    const { product: payloadProduct, img } = action.payload

    payloadProduct._img = img

    return {
      ...state,
    }
  }

  if (action.type === SEARCH_SET_QUERY) {
    return {
      ...state,
      query: action.payload,
    }
  }

  if (action.type === SEARCH_SET_REPLACE_ID) {
    return {
      ...state,
      replaceId: action.payload,
    }
  }

  throw new Error(`Действия ${action.type} не существует`)
}

export default searchReducer
