import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import { useAuthContext } from '../context/auth_context'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import capitalizeFirstLetter from '../utils/capitalizeFirstLetter'

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Минимум 2 символа').max(32, 'Максимум 32 символа').required('Это обязательное поле'),
  secondName: Yup.string().min(2, 'Минимум 2 символа').max(32, 'Максимум 32 символа').required('Это обязательное поле'),
  middleName: Yup.string().min(2, 'Минимум 2 символа').max(32, 'Максимум 32 символа').required('Это обязательное поле'),
  email: Yup.string().email('Неверный эмайл').required('Это обязательное поле'),
  password: Yup.string().min(5, 'Минимум 5 символов').max(32, 'Максимум 32 символа').required('Это обязательное поле'),
  passwordRepeat: Yup.string()
    .test('passwords-match', 'Пароли должны совпадать', function (value) {
      return this.parent.password === value
    })
    .min(5, 'Минимум 5 символов')
    .max(32, 'Максимум 32 символа')
    .required('Это обязательное поле'),
})

const SignUp = () => {
  const { createUser, isError, errorMessage, clearError, isLoading } = useAuthContext()

  const handleSubmit = (values) => {
    values.firstName = capitalizeFirstLetter(values.firstName)
    values.secondName = capitalizeFirstLetter(values.secondName)
    values.middleName = capitalizeFirstLetter(values.middleName)
    createUser(values)
  }

  return (
    <Wrapper>
      <Formik
        validationSchema={SignUpSchema}
        onSubmit={handleSubmit}
        initialValues={{
          firstName: '',
          secondName: '',
          middleName: '',
          email: '',
          password: '',
          passwordRepeat: '',
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="firstName">
              <Form.Label>Имя</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Введите ваше имя"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.firstName && !errors.firstName}
                isInvalid={!!errors.firstName && touched.firstName}
                feedback={errors.firstName}
              />
              <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="secondName">
              <Form.Label>Фамилия</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Введите вашу фамилию"
                name="secondName"
                value={values.secondName}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.secondName && !errors.secondName}
                isInvalid={!!errors.secondName && touched.secondName}
                feedback={errors.secondName}
              />
              <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.secondName}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="middleName">
              <Form.Label>Отчество</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Введите ваше отчество"
                name="middleName"
                value={values.middleName}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.middleName && !errors.middleName}
                isInvalid={!!errors.middleName && touched.middleName}
                feedback={errors.middleName}
              />
              <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.middleName}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email адрес</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Введите email"
                name="email"
                value={values.email}
                onChange={(e) => {
                  handleChange(e)
                  clearError()
                }}
                onBlur={handleBlur}
                isValid={touched.email && !errors.email && !isError}
                isInvalid={(!!errors.email || isError) && touched.email}
                feedback={errors.email}
              />
              <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.email || errorMessage}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Пароль</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Введите пароль"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.password && !errors.password}
                isInvalid={!!errors.password && touched.password}
                feedback={errors.password}
              />
              <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="passRepeat">
              <Form.Label>Повторите пароль</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Введите пароль еще раз"
                name="passwordRepeat"
                value={values.passwordRepeat}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.passwordRepeat && !errors.passwordRepeat}
                isInvalid={!!errors.passwordRepeat && touched.passwordRepeat}
                feedback={errors.passwordRepeat}
              />
              <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.passwordRepeat}</Form.Control.Feedback>
            </Form.Group>
            <button className="btn" type="submit" disabled={isLoading}>
              Регистрация
            </button>

            <Link className="btn btn_sm" to="/profile">
              Вернуться
            </Link>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: block;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;

  .btn {
    width: 100%;
    margin-top: 30px;

    &_sm {
      color: #474747;
      background-color: unset;
      margin-top: 15px;
    }
  }
`

export default SignUp
