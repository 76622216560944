import {
  TABLE_ADD_MANY_PRODUCTS,
  TABLE_ADD_PRODUCT,
  TABLE_CALC_TOTAL_PRICE,
  TABLE_CHANGE_PRODUCT,
  TABLE_CLEAR,
  TABLE_DELETE_PRODUCT,
  TABLE_ERASE_AFTER_SAVE,
  TABLE_LOAD,
  TABLE_LOAD_END,
  TABLE_LOAD_START,
  TABLE_REPLACE,
  TABLE_SAVED,
  TABLE_SAVE_ERROR,
  TABLE_SET_CHECKED,
  TABLE_UPDATE,
} from '../actions'

const tableReducer = (state, action) => {
  if (action.type === TABLE_ADD_PRODUCT) {
    return { ...state, products: { ...state.products, ...action.payload }, totalProducts: state.totalProducts + 1 }
  }

  if (action.type === TABLE_ADD_MANY_PRODUCTS) {
    const products = { ...state.products, ...action.payload }
    const totalProducts = Object.keys(products).length
    return { ...state, products, totalProducts }
  }
  if (action.type === TABLE_CHANGE_PRODUCT) {
    const [id, product] = action.payload
    product.coincidence = 1

    return {
      ...state,
      products: {
        ...state.products,
        [id]: product,
      },
    }
  }

  if (action.type === TABLE_CALC_TOTAL_PRICE) {
    const products = Object.values(state.products)
    return {
      ...state,
      totalBuyPrice: products.reduce(
        (summ, current) => (summ += ((parseFloat(current.buy_price) || 0) * parseFloat(current.amount)) / 10000),
        0
      ),
      totalPrice: products.reduce(
        (summ, current) => (summ += (parseFloat(current.price) * parseFloat(current.amount)) / 100),
        0
      ),
      totalInstallationPrice: products.reduce(
        (summ, product) => (summ += ((product.install_price / 100) * product.amount) / 100),
        0
      ),
    }
  }

  if (action.type === TABLE_DELETE_PRODUCT) {
    const newProducts = { ...state.products }
    delete newProducts[action.payload]
    return { ...state, products: newProducts, totalProducts: state.totalProducts - 1 }
  }

  if (action.type === TABLE_SAVED) {
    const { name } = action.payload
    return {
      ...state,
      name,
      saveError: false,
      saveSuccess: true,
    }
  }

  if (action.type === TABLE_SAVE_ERROR) {
    return {
      ...state,
      saveError: true,
      saveErrorMessage: action.payload.message,
    }
  }

  if (action.type === TABLE_ERASE_AFTER_SAVE) {
    return {
      ...state,
      saveError: false,
      saveSuccess: false,
    }
  }

  if (action.type === TABLE_LOAD) {
    const { data, name, _id: id } = action.payload
    const parsed = {
      ...JSON.parse(data),
    }
    return {
      ...parsed,
      totalProducts: Object.keys(parsed.products).length,
      name,
      id,
      saveError: false,
      saveSuccess: false,
      saveErrorMessage: undefined,
      updateId: -1,
    }
  }

  if (action.type === TABLE_LOAD_START) {
    return {
      ...state,
      isLoading: true,
    }
  }

  if (action.type === TABLE_LOAD_END) {
    return {
      ...state,
      isLoading: false,
    }
  }

  if (action.type === TABLE_UPDATE) {
    return {
      ...state,
      updateId: state.updateId ^ action.payload,
    }
  }

  if (action.type === TABLE_CLEAR) {
    return {
      products: {},
      totalPrice: 0,
      totalInstallationPrice: 0,
      name: undefined,
      saveError: false,
      saveSuccess: false,
      saveErrorMessage: undefined,
      totalProducts: 0,
      updateId: -1,
      isLoading: false,
    }
  }

  if (action.type === TABLE_SET_CHECKED) {
    const { bool, id } = action.payload
    const product = { ...state.products[id] }

    product.checked = bool

    return { ...state, products: { ...state.products, [id]: product } }
  }

  if (action.type === TABLE_REPLACE) {
    const [id, newProduct] = action.payload
    const product = { ...state.products[id] }
    return {
      ...state,
      products: {
        ...state.products,
        [id]: {
          ...newProduct,
          amount: product.amount,
          install_price: product.install_price,
        },
      },
    }
  }

  throw new Error(`Действия ${action.type} не существует`)
}

export default tableReducer
