import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import { useAuthContext } from '../context/auth_context'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'

const RecoverySchema = Yup.object().shape({
  password: Yup.string().min(5, 'Минимум 5 символов').max(32, 'Максимум 32 символа').required('Это обязательное поле'),
  passwordRepeat: Yup.string()
    .test('passwords-match', 'Пароли должны совпадать', function (value) {
      return this.parent.password === value
    })
    .min(5, 'Минимум 5 символов')
    .max(32, 'Максимум 32 символа')
    .required('Это обязательное поле'),
})

const RecoveryForm = ({ link }) => {
  const { recovery, auth } = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (auth) {
      navigate('/profile/Info')
    }

    // eslint-disable-next-line
  }, [auth])

  const handleSubmit = (values) => {
    recovery(link, values.password)
  }

  return (
    <Wrapper>
      <Formik
        validationSchema={RecoverySchema}
        onSubmit={handleSubmit}
        initialValues={{
          password: '',
          passwordRepeat: '',
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Пароль</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Введите пароль"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.password && !errors.password}
                isInvalid={!!errors.password && touched.password}
                feedback={errors.password}
              />
              <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="passRepeat">
              <Form.Label>Повторите пароль</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Введите пароль еще раз"
                name="passwordRepeat"
                value={values.passwordRepeat}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.passwordRepeat && !errors.passwordRepeat}
                isInvalid={!!errors.passwordRepeat && touched.passwordRepeat}
                feedback={errors.passwordRepeat}
              />
              <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.passwordRepeat}</Form.Control.Feedback>
            </Form.Group>
            <button className="btn" type="submit">
              Восстановить
            </button>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: block;
  max-width: 50%;
  margin: 0 auto;

  @media (max-width: 690px) {
    max-width: unset;
    width: 100%;
    padding: unset;
  }

  .btn {
    width: 100%;
    margin-top: 30px;
  }
  .input-group {
    margin-bottom: 15px;
  }
`

export default RecoveryForm
