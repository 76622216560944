import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import { useTableContext } from '../context/table_context'
import { removeAnyNonDigitCharsFromString } from '../utils/helpers'
import Confirm from './Confirm'
import ProductLink from './ProductLink'
import { CiWarning } from 'react-icons/ci'
import ReplaceButton from './ReplaceButton'
import stub from '../assets/media/stub.png'

const TableRow = ({ product, id }) => {
  const { deleteProduct, changeProduct, updateId } = useTableContext()

  const [state, dispatch] = useReducer((state, action) => {
    if (action.type === 'update') {
      return {
        ...action.payload,
      }
    }

    return {
      ...state,
      [action.field]: action.payload,
      coincidence: 1,
    }
  }, product)

  const handleBlur = useCallback(() => {
    changeProduct(id, state)
  }, [id, changeProduct, state])

  useEffect(() => {
    dispatch({
      type: 'update',
      payload: product,
    })
  }, [product])

  return useMemo(
    () => (
      <Wrapper>
        <div className="table__row">
          {/* Фото  */}
          <div className="table__col">
            <div className="product-img">
              {state.coincidence < 0.8 ? (
                <CiWarning size={50} color="#e43024" style={{ display: 'block', margin: '0 auto' }} />
              ) : (
                <img
                  onError={(event) => (event.target.src = stub)}
                  src={state.img || state._img || stub}
                  alt="product"
                />
              )}
            </div>
          </div>

          {/* info */}
          <div className="table__col">
            <div>
              <input
                onBlur={handleBlur}
                type="text"
                value={state.code}
                data-text
                placeholder="Введите артикул"
                onChange={(e) =>
                  dispatch({
                    field: 'code',
                    payload: e.target.value,
                  })
                }
              />
            </div>
            <div title={state.name}>
              <input
                onBlur={handleBlur}
                type="text"
                value={state.name}
                data-text
                placeholder="Введите название"
                onChange={(e) =>
                  dispatch({
                    field: 'name',
                    payload: e.target.value,
                  })
                }
              />
            </div>
            <ProductLink
              url={state.url}
              id={id}
              onChange={(value) =>
                dispatch({
                  field: 'url',
                  payload: value,
                })
              }
            />
          </div>

          {/* Ед. измерения */}
          <div className="table__col">
            <input
              onBlur={handleBlur}
              type="text"
              value={state.unit || state.measure}
              onChange={(e) =>
                dispatch({
                  field: 'unit',
                  payload: e.target.value,
                })
              }
              data-text
              placeholder="-"
            />
          </div>

          {/* Кол-во */}
          <div className="table__col">
            <NumberFormat
              onBlur={handleBlur}
              thousandsGroupStyle="thousand"
              value={state.amount / 100}
              prefix=""
              decimalSeparator=","
              displayType="input"
              type="text"
              thousandSeparator=" "
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              defaultValue={0}
              placeholder={0}
              onChange={(e) =>
                dispatch({
                  field: 'amount',
                  payload: ~~removeAnyNonDigitCharsFromString(e.target.value),
                })
              }
            />
          </div>
          {/* Цена монтажа */}
          <div className="table__col">
            <NumberFormat
              onBlur={handleBlur}
              thousandsGroupStyle="thousand"
              value={state.install_price / 100}
              prefix=""
              decimalSeparator=","
              displayType="input"
              type="text"
              thousandSeparator=" "
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              defaultValue={0}
              placeholder={0}
              onChange={(e) =>
                dispatch({
                  field: 'install_price',
                  payload: ~~removeAnyNonDigitCharsFromString(e.target.value),
                })
              }
            />
          </div>

          {/* Цена */}
          <div className={`table__col${state.exact_price ? '' : ' color_red'}`}>
            <NumberFormat
              onBlur={handleBlur}
              thousandsGroupStyle="thousand"
              value={state.price}
              prefix=""
              decimalSeparator=","
              displayType="input"
              type="text"
              thousandSeparator=" "
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              defaultValue={0}
              placeholder={0}
              onChange={(e) =>
                dispatch({
                  field: 'price',
                  payload: removeAnyNonDigitCharsFromString(e.target.value) / 100,
                })
              }
            />
            <NumberFormat
              onBlur={handleBlur}
              thousandsGroupStyle="thousand"
              value={state.buy_price / 100 || 0}
              prefix=""
              decimalSeparator=","
              displayType="input"
              type="text"
              thousandSeparator=" "
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              defaultValue={0}
              placeholder={0}
              onChange={(e) =>
                dispatch({
                  field: 'buy_price',
                  payload: removeAnyNonDigitCharsFromString(e.target.value),
                })
              }
            />
          </div>

          {/* Куплено */}
          <div className="table__col">
            <NumberFormat
              onBlur={handleBlur}
              thousandsGroupStyle="thousand"
              value={state.bought / 100 || 0}
              prefix=""
              decimalSeparator=","
              displayType="input"
              type="text"
              thousandSeparator=" "
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              defaultValue={0}
              placeholder={0}
              onChange={(e) =>
                dispatch({
                  field: 'bought',
                  payload: removeAnyNonDigitCharsFromString(e.target.value),
                })
              }
            />
          </div>

          {/* Действие */}
          <div className="table__col">
            <ReplaceButton productId={id} query={state.name} />
            <Confirm
              title="Удалить строку"
              onSubmit={() => {
                deleteProduct(id)
              }}
            >
              <div className="color_red action">Удалить</div>
            </Confirm>
          </div>
        </div>
      </Wrapper>
    ),
    // eslint-disable-next-line
    [state, product, updateId]
  )
}

const Wrapper = styled.div`
  border-bottom: 2px solid #e4e4e4;

  .action {
    text-decoration: underline;
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  &:nth-child(even) {
    background-color: #fff;
  }
  .pointer {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .checker {
    position: relative;
  }

  .checker:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 20px;
    cursor: pointer;
  }

  .product-img {
    overflow: hidden;

    img {
      max-width: 68px;
      mix-blend-mode: multiply;
      display: block;
      margin: 0 auto;
    }
  }

  input {
    box-sizing: border-box;
    width: 100%;
    display: block;
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: inherit;
    line-height: inherit;
    outline: none;
    font-weight: inherit;
    color: inherit;

    &[data-text] {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`

export default TableRow
