import React, { useState } from 'react'
import * as Yup from 'yup'
import 'yup-phone'
import { Formik } from 'formik'
import { Form, Modal, ToastContainer, Toast } from 'react-bootstrap'
import ReactInputMask from 'react-input-mask'
import { sendZoomRequest } from '../http/sendZoomRequest'

const ZoomSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Минимум 2 символа').max(32, 'Максимум 32 символа').required('Это обязательное поле'),
  email: Yup.string().email('Неверный эмайл').required('Это обязательное поле'),
  phone: Yup.string().phone('RU', true, 'Неверный номер телефона').required('Это обязательное поле'),
})

const ZoomModal = () => {
  const [show, setShow] = useState(false)
  const [showToast, setShowToast] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleCloseToast = () => {
    setShowToast(false)
  }

  const handleOpenToast = () => {
    setShowToast(true)
  }

  const handleSubmit = async (values) => {
    try {
      await sendZoomRequest(values)
      handleOpenToast()
      handleClose()
      window.scrollTo(0, 0)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <ToastContainer position="top-start" className="p-3">
        <Toast show={showToast} delay={500} onClose={handleCloseToast}>
          <Toast.Header>
            <strong className="me-auto">Заявка отправлена</strong>
          </Toast.Header>
          <Toast.Body>Мы с вами свяжемся!</Toast.Body>
        </Toast>
      </ToastContainer>

      <div className="button" onClick={handleShow}>
        Записаться на ZOOM
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Запись на ZOOM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={ZoomSchema}
            onSubmit={handleSubmit}
            initialValues={{
              firstName: '',
              email: '',
              phone: '',
            }}
          >
            {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>Номер телефона</Form.Label>
                  <ReactInputMask
                    className="form-control"
                    mask="+7 (999) 999-99-99"
                    required
                    type="text"
                    placeholder="Введите номер телефона"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    feedback={errors.phone}
                  ></ReactInputMask>
                  <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="firstName">
                  <Form.Label>Имя</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Введите ваше имя"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.firstName && !errors.firstName}
                    isInvalid={!!errors.firstName && touched.firstName}
                    feedback={errors.firstName}
                  />
                  <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email адрес</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Введите email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.email && !errors.email}
                    isInvalid={!!errors.email && touched.email}
                    feedback={errors.email}
                  />
                  <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="d-flex justify-content-start pb-3">
                  <button className="button" type="submit">
                    Записаться
                  </button>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ZoomModal
