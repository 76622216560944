import React from 'react'
import styled from 'styled-components'
import { useAuthContext } from '../../context/auth_context'

const AdminPanel = () => {
  const { auth } = useAuthContext()

  if (auth.user.role !== 'admin') {
    return <Wrapper>Нет доступа</Wrapper>
  }
  return <Wrapper>Админ панель</Wrapper>
}

const Wrapper = styled.div`
  display: block;
`

export default AdminPanel
