import { useCallback } from 'react'
import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'

const Confirm = ({
  children,
  title = 'Подтверждение',
  body = 'Вы действительно хотите выполнить это действие?',
  onSubmit = () => {},
  trueText = 'Подтвердить',
  falseText = 'Отменить',
}) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleTrue = useCallback(() => {
    handleClose()
    onSubmit()
  }, [onSubmit])

  return (
    <>
      <div onClick={handleShow}>{children}</div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="px-3" onClick={handleClose}>
            {falseText}
          </Button>
          <Button variant="primary" className="px-3" onClick={handleTrue}>
            {trueText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Confirm
