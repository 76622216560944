import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTableContext } from '../context/table_context'
import ReactConfetti from 'react-confetti'

const StorageTableFooterRow = () => {
  const [showConfetti, setShowConfetti] = useState(false)
  const { products } = useTableContext()
  const boughtProducts = Object.values(products).filter((product) => product.bought > 0)
  const totalProducts = boughtProducts.reduce((sum, product) => (sum += product.bought / 100), 0)
  const totalInstalled = boughtProducts.reduce((sum, product) => (sum += (product.installed || 0) / 100), 0)
  const averagePercentage =
    boughtProducts.reduce((sum, product) => (sum += (product.installed || 0) / 100 / (product.bought / 10000)), 0) /
      boughtProducts.length || 0

  useEffect(() => {
    if (averagePercentage < 100) {
      return
    }
    setShowConfetti(true)
    const timeout = setTimeout(() => setShowConfetti(false), 5000)

    return () => clearInterval(timeout)
  }, [averagePercentage])
  return (
    <Wrapper>
      {showConfetti && <ReactConfetti gravity={0.05} height={document.body.scrollHeight - 200} opacity={0.6} />}
      <div className="table__row">
        <div className="table__col"></div>
        <div className="table__col">
          <strong>Итого</strong>
        </div>
        <div className="table__col"></div>
        <div className="table__col">{totalProducts}</div>
        <div className="table__col">{totalInstalled}</div>
        <div className="table__col">{totalProducts - totalInstalled}</div>
        <div className="table__col">{averagePercentage.toFixed(2)} %</div>
        <div className="table__col"></div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  strong {
    font-weight: bold;
    font-size: 16px;
  }
`

export default StorageTableFooterRow
