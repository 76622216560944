import React, { useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { useSearchContext } from '../context/search_context'
import SearchRow from './SearchRow'

const Search = () => {
  const { foundProducts, close, isOpen, clear, query, setQuery, replaceId } = useSearchContext()
  const dropDown = useRef(null)
  const dropDownClearScroll = () => {
    dropDown.current.scroll(0, 0)
  }
  const onInput = useCallback((e) => {
    setQuery(e.target.value)

    // eslint-disable-next-line
  }, [])

  const onClear = useCallback((e) => {
    clear()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dropDownClearScroll()

    // eslint-disable-next-line
  }, [isOpen])
  return (
    <>
      {replaceId && <HeightFix />}
      <Wrapper data-replacing={!!replaceId}>
        <div className="input-group">
          <label className="text">Введите наименование товара</label>
          <input
            type="text"
            placeholder="Введите наименование"
            value={query}
            onInput={onInput}
            className="search__input"
          />
          <div className="input-group__dropdown dropdown" hidden={!isOpen} ref={dropDown} onClick={close}>
            {foundProducts.map((product) => (
              <SearchRow key={product.id} product={product} />
            ))}
          </div>
          <div className="input-group__clear clear" onClick={onClear} hidden={!query.length}></div>
        </div>
      </Wrapper>
    </>
  )
}

const HeightFix = styled.div`
  min-height: 67px;
`

const Wrapper = styled.div`
  &[data-replacing='true'] {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 9999;
    padding: 1rem 2rem;

    &:before {
      content: '';
      position: absolute;
      width: 100vw;
      height: 100vh;
      background-color: rgba(255, 255, 255, 0.8);
      top: 0;
      left: 0;
    }
  }

  .dropdown {
    position: absolute;
    top: 70px;
    max-height: 400px;
    min-height: 80px;
    width: 100%;
    overflow: hidden;
    z-index: 10;
    border-radius: 10px !important;
    border: 2px solid #e4e4e4;
    overflow: hidden;
    margin-top: 5px;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
  }

  .dropdown::before {
    content: 'Пусто';
    color: #000;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown::after {
    content: '';
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
  }

  .dropdown__item {
    display: flex;
    position: relative;
    padding: 10px 20px;
    background-color: #fbfbfb;
    transition: all 0.25s ease;
    width: 100%;
  }

  .dropdown__item:nth-child(even) {
    background: #fff;
  }

  .dropdown__item:hover {
    background-color: #e4e4e4;
  }

  .dropdown__item__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 15px;
    width: 100%;
  }

  .dropdown__item__photo,
  .list-table__photo {
    max-height: 68px;
    overflow: hidden;
  }

  .dropdown__item__photo img,
  .list-table__photo img {
    width: 68px;
    mix-blend-mode: multiply;
  }
  @media screen and (max-width: 1040px) {
    .dropdown {
      font-size: 16px;
    }

    .dropdown__item__info > .text {
      margin: 10px 0;
    }
  }
`

export default Search
