import $server from '../http/index.js'
const sendFeedback = async (message) => {
  return await $server
    .post('/user/feedback', {
      message,
    })
    .then((res) => res.data)
}

export default sendFeedback
