import React, { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { usePaymentContext } from '../../context/payment_context'
import AddCard from '../AddCard'
import NewSub from '../NewSub'
import SubInfo from '../SubInfo'

const Subscription = () => {
  const { card, subscription, isError, isLoading, errorMessage, clearError, update } = usePaymentContext()
  useEffect(() => {
    update()
    // eslint-disable-next-line
  }, [])

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner animation="border" variant="danger" />
      </div>
    )
  }

  if (isError) {
    return (
      <>
        <div className="mb-1 h5 text-danger">Произошла ошибка.</div>
        <div className="mb-5 h5">{errorMessage || 'Попробуйте еще раз или обратитесь к администратору.'}</div>
        <button onClick={clearError} className="btn">
          Назад
        </button>
      </>
    )
  }

  if (subscription && card) {
    return <SubInfo />
  }

  if (card) {
    return <NewSub />
  }

  return <AddCard />
}

export default Subscription
