import React, { useState } from 'react'
import styled from 'styled-components'
import AuthForms from '../components/AuthForms'

import { Col, Nav, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

import { useAuthContext } from '../context/auth_context'
import { AdminPanel, Feedback, Info, SavedTables, Security, Settings, Subscription } from '../components/Profile'

import { BiUser, BiShield, BiLogOut, BiData, BiWallet, BiWrench, BiPlanet, BiSupport } from 'react-icons/bi'
import { useEffect } from 'react'

const renderTab = (tab) => {
  switch (tab) {
    case 'Info':
      return <Info />
    case 'Security':
      return <Security />
    case 'SavedTables':
      return <SavedTables />
    case 'Subscription':
      return <Subscription />
    case 'Settings':
      return <Settings />
    case 'AdminPanel':
      return <AdminPanel />
    case 'Feedback':
      return <Feedback />
    default:
      return 'Неверный адрес'
  }
}

const ProfilePage = () => {
  const { signOutApp, auth } = useAuthContext()
  const { defaultTab } = useParams()
  const navigate = useNavigate()

  const [tab, setTab] = useState(defaultTab)

  const handleSelect = (eventKey) => {
    setTab(eventKey)
    navigate('/profile/' + eventKey)
  }

  useEffect(() => {
    handleSelect(defaultTab || 'Info')
    // eslint-disable-next-line
  }, [defaultTab])

  if (!auth) {
    return (
      <Wrapper>
        <div className="container">
          <h2>Авторизация</h2>
          <AuthForms />
        </div>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <div className="container">
        <h2 className="page-title">Управление аккаунтом</h2>
        <Row className="border rounded">
          <Col sm={4} className="p-0 py-3 border-right">
            <Nav variant="pills" className="flex-column profile-menu" activeKey={tab} onSelect={handleSelect}>
              <Nav.Item>
                <h3 className="text-center mb-3 ">Разделы</h3>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Info" href="#">
                  <BiUser className="link-icon" />
                  Основная информация
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Security" href="#">
                  <BiShield className="link-icon" />
                  Безопасность
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="SavedTables" href="#" disabled={auth.user.role === 'member'}>
                  <BiData className="link-icon" />
                  Сохраненные таблицы
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Subscription" href="#">
                  <BiWallet className="link-icon" />
                  Подписка
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="Settings" href="#" disabled>
                  <BiWrench className="link-icon" />
                  Настройки (в разработке)
                </Nav.Link>
              </Nav.Item>
              {auth.user.role === 'admin' && (
                <Nav.Item>
                  <Nav.Link eventKey="AdminPanel" href="#">
                    <BiPlanet className="link-icon" />
                    Админ панель
                  </Nav.Link>
                </Nav.Item>
              )}

              <Nav.Item>
                <Nav.Link eventKey="Feedback" href="#">
                  <BiSupport className="link-icon" />
                  Обратная связь
                </Nav.Link>
              </Nav.Item>

              <Nav.Item className="mt-auto exit">
                <button className="btn" onClick={signOutApp}>
                  <BiLogOut className="link-icon" />
                  Выход
                </button>
              </Nav.Item>
            </Nav>
          </Col>

          <Col className="py-5 px-5">{renderTab(tab)}</Col>
        </Row>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: block;

  [aria-disabled='true'] {
    opacity: 0.3 !important;
  }

  .btn {
    width: 100%;
    margin-top: 0.5rem;
  }

  .link-icon {
    margin-right: 0.5rem;
  }

  .border {
    border-width: 2px !important;
    border-radius: 10px !important;
  }

  .border-right {
    border-right: 2px solid #dee2e6 !important;
  }

  .exit .btn {
    background-color: transparent;
    color: #e43024;
    width: auto;
    padding: 0 1rem;
  }

  .profile-menu {
    min-height: 60vh;

    @media screen and (max-width: 575px) {
      min-height: unset;
    }
  }

  .nav-link {
    color: #474747;
    opacity: 0.7;
    position: relative;
  }

  .nav-link.active {
    background-color: transparent;
    color: #474747;
    opacity: 1;
  }

  .nav-link.active:after {
    content: '';
    display: block;
    position: absolute;
    width: 3px;
    height: 100%;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: #e43024;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
`

export default ProfilePage
