import React, { useContext, useMemo, useReducer } from 'react'
import { useEffect } from 'react'
import { SPEC_ADD, SPEC_DONE, SPEC_RESET_ISDONE, SPEC_START, SPEC_TOGGLE_MODAL } from '../actions'
import $server from '../http'
import specSearchReducer from '../reducers/SpecSearch_reducer'
import { useTableContext } from './table_context'
import ProductDto from '../dtos/ProductDto.mjs'
import { token_set_ratio } from 'fuzzball'
import distance from 'jaro-winkler'

const SpecSearchContext = React.createContext()

const initialState = {
  isModalOpen: false,
  isStarted: false,
  isDone: false,
  isError: false,
  spec: [],
}

export const SpecSearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(specSearchReducer, initialState)
  const { addManyProducts } = useTableContext()

  useEffect(() => {
    if (state.spec.length === 0) {
      return
    }

    dispatch({
      type: SPEC_START,
    })

    Promise.all(
      state.spec.map((item) => {
        return $server.post('/search', {
          query: String(item['Наименование']),
          isSpec: true,
        })
      })
    ).then(async (search) => {
      const res = {}
      for (let index = 0; index < search.length; index++) {
        const [result] = search[index].data

        if (!result) {
          continue
        }

        let product = new ProductDto(result)
        const id = Date.now() + index
        const item = state.spec[index]
        const strings = [String(item['Наименование']), String(product.name)]

        product.coincidence = product.code === item.code ? 1 : distance(...strings)

        if (product.coincidence < 0.8) {
          product.coincidence = token_set_ratio(...strings) / 100
        }

        if (product.coincidence < 0.8) {
          product = new ProductDto({
            id,
            name: item['Наименование'],
            code: item['Артикул'],
            exact_price: true,
            coincidence: product.coincidence,
          })
        }

        product.amount = (parseInt(item['Кол-во']) || 1) * 100
        product.install_price = 0

        res[id] = product
      }

      addManyProducts(res)

      dispatch({
        type: SPEC_DONE,
      })
    })

    // eslint-disable-next-line
  }, [state.spec])

  const addSpec = (spec) => {
    dispatch({
      type: SPEC_ADD,
      payload: spec,
    })
  }

  const toggleModal = (status = !state.isModalOpen) => {
    dispatch({
      type: SPEC_TOGGLE_MODAL,
      payload: status,
    })
  }

  const resetIsDone = () => {
    dispatch({
      type: SPEC_RESET_ISDONE,
    })
  }

  return useMemo(
    () => (
      <SpecSearchContext.Provider value={{ ...state, addSpec, toggleModal, resetIsDone }}>
        {children}
      </SpecSearchContext.Provider>
    ),
    // eslint-disable-next-line
    [state]
  )
}

export const useSpecSearchContext = () => useContext(SpecSearchContext)
