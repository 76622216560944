import { AUTH_ERROR, AUTH_ERROR_CLEAR, AUTH_LOAD, AUTH_SUCCESS } from '../actions'

const authReduser = (state, action) => {
  if (action.type === AUTH_LOAD) {
    return { ...state, isLoading: true, isError: false }
  }

  if (action.type === AUTH_SUCCESS) {
    return { ...state, isLoading: false, auth: action.payload }
  }

  if (action.type === AUTH_ERROR) {
    const { errors, mssage } = action.payload
    return { ...state, isLoading: false, isError: true, errors, errorMessage: mssage }
  }

  if (action.type === AUTH_ERROR_CLEAR) {
    return { ...state, isError: false, errors: [], errorMessage: null }
  }

  throw new Error(`Действия ${action.type} не существует`)
}

export default authReduser
