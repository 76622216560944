import { BsPlusCircle } from 'react-icons/bs'
import { useSpecSearchContext } from '../context/spec_search_context'
import { useCallback } from 'react'
import CustomModal from './CustomModal'
import AddSpecification from './AddSpecification'
import { Spinner, Toast, ToastContainer } from 'react-bootstrap'

const SpecSearchFeature = () => {
  const { isStarted, toggleModal, resetIsDone, isModalOpen, isDone } = useSpecSearchContext()

  const handleCloseAddModal = useCallback(() => {
    toggleModal(false)
  }, [toggleModal])
  const handleShowAddModal = useCallback(() => {
    toggleModal(true)
  }, [toggleModal])

  return (
    <>
      <CustomModal show={isModalOpen} onHide={handleCloseAddModal} title="Добавить позиции">
        <AddSpecification />
      </CustomModal>
      <ToastContainer position="top-start" className="p-3">
        <Toast show={isDone} autohide delay={3000} onClose={resetIsDone}>
          <Toast.Header closeButton={false}>Поиск по спецификации</Toast.Header>
          <Toast.Body>
            <strong>Завершен</strong>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <ToastContainer position="top-start" className="p-3">
        <Toast show={isStarted} style={{ width: '200px' }}>
          <Toast.Header closeButton={false}>Поиск по спецификации</Toast.Header>
          <Toast.Body className="d-flex justify-content-center">
            <Spinner animation="border" role="status" className="m-0-auto">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <div className={`btn_add${isStarted ? ' disabled' : ''}`} onClick={handleShowAddModal}>
        <span>Добавить позиции</span>
        <BsPlusCircle className="btn__icon" />
      </div>
    </>
  )
}

export default SpecSearchFeature
