import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { AuthProvider } from './context/auth_context'
import { PaymentProvider } from './context/payment_context'
import { SearchProvider } from './context/search_context'
import { SpecSearchProvider } from './context/spec_search_context'
import { TableProvider } from './context/table_context'

ReactDOM.render(
  <AuthProvider>
    <TableProvider>
      <SearchProvider>
        <SpecSearchProvider>
          <PaymentProvider>
            <Router>
              <App />
            </Router>
          </PaymentProvider>
        </SpecSearchProvider>
      </SearchProvider>
    </TableProvider>
  </AuthProvider>,
  document.getElementById('root')
)
