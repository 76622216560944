import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import SignIn from '../components/SignIn'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Form } from 'react-bootstrap'
import useNotification from '../hooks/useNotification'
import { useAuthContext } from '../context/auth_context'

const recoverySchema = Yup.object().shape({
  email: Yup.string().email('Неверный эмайл').required('Это обязательное поле'),
})

const AuthForms = () => {
  const { sendRecovery } = useAuthContext()
  const [recoveryShow, setRecoveryShow] = useState(false)
  const [displayNotification, Notification] = useNotification({
    title: 'Восстановление доступа',
    message: 'Письмо для восстановления доступа отправлено на ваш email адрес.',
  })
  const handleRecovery = ({ email }, formikBag) => {
    sendRecovery(email)
    formikBag.resetForm()
    setRecoveryShow(false)
    displayNotification()
  }
  return (
    <Wrapper>
      <Notification />
      <Modal size="lg" show={recoveryShow} onHide={() => setRecoveryShow(false)} aria-labelledby="recoveryTitle">
        <Modal.Header closeButton>
          <Modal.Title id="recoveryTitle">Восстановление аккаунта</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={recoverySchema}
            onSubmit={handleRecovery}
            initialValues={{
              email: '',
            }}
          >
            {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email адрес от аккаунта</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Введите email"
                    name="email"
                    value={values.email}
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                    isValid={touched.email && !errors.email}
                    isInvalid={!!errors.email && touched.email}
                    feedback={errors.email}
                  />
                  <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
                <button className="btn w-100" type="submit">
                  Восстановить
                </button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <SignIn />
      <Link className="btn" to="/signup">
        Регистрация
      </Link>
      <button className="btn btn_sm" onClick={() => setRecoveryShow(true)}>
        Забыли пароль?
      </button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: block;

  .btn {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    margin-top: 20px;

    &_sm {
      background-color: unset;
      color: #212529;
      margin-top: 10px;
    }
  }
`

export default AuthForms
