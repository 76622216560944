const roles = {
  member: 'Участник',
  admin: 'Администратор',
  subscriber: 'Подписчик',
}

/**
 * @param {keyof roles} str
 * @returns {roles[keyof roles]}
 */

export const getRole = (str) => {
  return roles[str]
}
