import { Modal } from 'react-bootstrap'

const AcsWindow = ({ url }) => {
  return (
    <Modal size="lg" centered backdrop="static" keyboard={false} show={true}>
      <Modal.Body>
        <iframe style={{ width: '100%', height: '90vh', minHeight: '500px' }} src={url} title="acs"></iframe>
      </Modal.Body>
    </Modal>
  )
}

export default AcsWindow
