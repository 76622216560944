import '../assets/styles/landing.css'
import biglogo from '../assets/media/logo-big.svg'
import rednwhitelogo from '../assets/media/enprice_logo_rednwhite.svg'
import videopreview from '../assets/media/video_preview.png'

import Tilt from 'react-tilt'

import React, { useEffect, useState } from 'react'
import useQuery from '../hooks/useQuery'
import { notifications } from '../utils/constants'
import { Modal, ModalHeader, Toast, ToastContainer } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ZoomModal from '../components/ZoomModal'

const HomePage = () => {
  const query = useQuery()
  const [showToast, setShowToast] = useState(false)
  const [toastTitle, setToastTitle] = useState('')
  const [toastMessage, setToastMessage] = useState('')
  const [showVideo, setShowVideo] = useState(false)

  const handleCloseVideo = () => {
    setShowVideo(false)
  }

  const handleOpenVideo = () => {
    setShowVideo(true)
  }

  const handleClose = () => {
    setShowToast(false)
  }

  const handleOpen = () => {
    setShowToast(true)
  }

  const scrollTop = () => {
    document.body.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    const notification = notifications[query.notification]
    if (notification) {
      setToastTitle(notification.title)
      setToastMessage(notification.message)

      handleOpen()
    }
  }, [query])
  return (
    <>
      <ToastContainer position="top-start" className="p-3">
        <Toast show={showToast} delay={500} onClose={handleClose}>
          <Toast.Header>
            <strong className="me-auto">{toastTitle}</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      <Modal show={showVideo} onHide={handleCloseVideo} size="lg" centered>
        <ModalHeader closeButton>
          <Modal.Title>Как пользоваться сервисом?</Modal.Title>
        </ModalHeader>

        <Modal.Body>
          <iframe
            width="100%"
            height="431px"
            src="https://www.youtube.com/embed/P8b1EVNYJZ8"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Modal.Body>
      </Modal>

      <section className="landing-section section_bg_main">
        <div className="landing-container">
          <div className="head">
            <img className="head__logo" src={biglogo} alt="enprice" />
            <div className="head__subtitle">надежный помощник</div>
            <div className="head__description">Мы собрали цены самых популярных интернет магазинов в одном месте</div>
            <div className="head__footer">
              <div className="button">
                <Link to="/search">начать бесплатно</Link>
              </div>
              <ZoomModal />
            </div>
          </div>
        </div>
      </section>

      <section className="landing-section offer">
        <div className="landing-container">
          <div className="offer__title">Кому это подходит?</div>
          <div className="offer__container">
            <div className="offer__item">
              <Tilt className="card Tilt" options={{ max: 25, scale: 1.05 }}>
                <div className="card__content Tilt-inner">
                  <div className="card__header">
                    <div className="card__icon card__icon_variant_doc"></div>
                    <div className="card__title">
                      <span>сметчикам</span>
                      <span className="card__break"></span>
                      <span>проектировщикам</span>
                    </div>
                  </div>
                  <div className="card__text">
                    Найди и сравни цены на оборудование АПС, СОУЭ, СКС, СКУД,ОС, Видео и других систем в 1 клик
                  </div>
                </div>
              </Tilt>
            </div>

            <div className="offer__item">
              <Tilt className="card Tilt" options={{ max: 25, scale: 1.05 }}>
                <div className="card__content Tilt-inner">
                  <div className="card__header">
                    <div className="card__icon card__icon_variant_wrench"></div>
                    <div className="card__title">
                      <span>инженерам</span>
                      <span className="card__break"></span>
                      <span>монтажникам</span>
                    </div>
                  </div>
                  <div className="card__text">Посчитай цену монтажа и оборудования прямо на объекте за 5 минут</div>
                </div>
              </Tilt>
            </div>
          </div>
        </div>
      </section>

      <section className="landing-section advantages">
        <div className="landing-container advantages__container">
          <div className="tile tile_icon_1">
            <div className="tile__text">
              Доступ к свыше <span className="accent">150 000</span> <span className="accent">товаров</span> с фото,
              ценой и описанием
            </div>
          </div>
          <div className="tile tile_icon_2">
            <div className="tile__text">
              сохраняй и скачивай расчёт в <span className="accent">Excel</span>
            </div>
          </div>
          <div className="tile tile_icon_3">
            <div className="tile__text">
              сохраняй расчёт с <span className="accent">телефона </span> или <span className="accent">планшета</span>
            </div>
          </div>
        </div>
      </section>

      <section className="landing-section video">
        <div className="landing-container video__container">
          <div className="video__block">
            <div className="video__controls">
              <div className="video__title">
                Как пользоваться <span>сервисом</span>?
              </div>
              <div className="video__play" onClick={handleOpenVideo}></div>
              <img src={rednwhitelogo} alt="enprice" className="video__logo" />
            </div>
            <img src={videopreview} alt="preview" />
          </div>
        </div>
      </section>

      <section className="landing-section how">
        <div className="landing-container how__container">
          <div className="how__title">
            Как это работает? <br /> в <span className="accent">5 шагах</span>
          </div>

          <div className="how__steps">
            <div className="how__step">
              <div>
                <span className="accent">
                  <Link to="/profile/Info">Войди </Link>
                </span>
                или <br />
                <span className="accent">
                  <Link to="/signup">зарегистрируйся</Link>
                </span>
              </div>
            </div>
            <div className="how__step">
              <div>
                В строке
                <span className="accent">
                  <Link to="search"> поиска </Link>
                </span>
                введи <br /> наименование
                <span className="accent"> товара</span>
              </div>
            </div>
            <div className="how__step">
              <div>
                Система подберёт для <span className="accent">тебя</span>
                <br /> подходящие товары
                <span className="accent"> в наличии</span>
              </div>
            </div>
            <div className="how__step">
              <div>
                <span className="accent">Одним щелчком</span> <br /> добавь товар в список
              </div>
            </div>
            <div className="how__step">
              <div>
                Сохрани или загрузи <br /> список в <span className="accent">Excel</span>
              </div>
            </div>
          </div>
        </div>

        <div className="how__top-button top-button" onClick={scrollTop}>
          наверх
        </div>
      </section>

      <footer className="landing-section footer">
        <div className="landing-container footer__container">
          © ENPRICE <span>{new Date().getFullYear()}</span>
        </div>
      </footer>
    </>
  )
}

export default HomePage
