import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../assets/media/logo.svg'
import { useAuthContext } from '../context/auth_context'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'
const Header = () => {
  const { auth } = useAuthContext()
  const [isMenuActive, setMenuActive] = useState(false)

  const toggleMenu = () => {
    setMenuActive((prev) => !prev)
  }
  return (
    <div className="landing-container">
      <HeaderBox>
        <Link to="/">
          <Logo className="logo" />
        </Link>
        <div className="header__menu" onClick={toggleMenu}>
          {isMenuActive ? <AiOutlineClose /> : <AiOutlineMenu />}
        </div>
        <ul className={['header__nav', isMenuActive ? 'active' : ''].join(' ')}>
          {auth && (
            <li className="button" onClick={toggleMenu}>
              <Link to="/profile/Feedback">Обратная связь</Link>
            </li>
          )}

          <li className="button" onClick={toggleMenu}>
            <Link to="/search">Поиск</Link>
          </li>

          <li className="button" onClick={toggleMenu}>
            <Link to="/storage">Склад</Link>
          </li>
          <li className="button" onClick={toggleMenu}>
            <Link to="/profile/Info">Аккаунт</Link>
          </li>
        </ul>
      </HeaderBox>
    </div>
  )
}
const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
  position: relative;
  z-index: 9999;

  .logo {
    width: clamp(94px, 19.583vw, 124px);
  }

  .header__menu {
    display: none;
  }

  @media (max-width: 768px) {
    .header__menu {
      display: block;
      font-size: 1.8rem;
    }
  }
`

export default Header
