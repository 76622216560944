import React from 'react'
import styled from 'styled-components'
import useUserTables from '../../hooks/useUserTables'
import OneTable from './OneTable'

const SavedTables = () => {
  const [tables, loading, error] = useUserTables()

  if (loading) {
    return 'Загрузка...'
  }

  if (error) {
    return <div>{error}</div>
  }

  if (tables.length === 0) {
    return 'Пусто...'
  }

  return (
    <Wrapper>
      {tables.map((table) => (
        <OneTable key={table._id} name={table.name} updated_at={table.updated_at} id={table._id} />
      ))}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: block;
`

export default SavedTables
