import React from 'react'
import styled from 'styled-components'
import { AiOutlineDelete } from 'react-icons/ai'

const DeleteButton = ({ onClick = () => {} }) => {
  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()

    onClick()
  }
  return (
    <Wrapper onClick={handleClick}>
      <AiOutlineDelete />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-100%, -50%);

  transition: all 0.25s ease;

  cursor: pointer;

  &:hover {
    color: red;
  }

  &:after {
    content: '';
    position: absolute;
    left: -50%;
    top: -50%;
    padding: 50%;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
  }
`

export default DeleteButton
