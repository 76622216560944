import styled from 'styled-components'
import { useFilePicker } from 'use-file-picker'
import Table from 'react-bootstrap/Table'
import useTableParser from '../hooks/useTableParser'
import { Form, NavDropdown } from 'react-bootstrap'
import { useCallback } from 'react'
import { useSpecSearchContext } from '../context/spec_search_context'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useTableContext } from '../context/table_context'

const ItemSchema = Yup.object().shape({
  code: Yup.string().required('Это обязательное поле'),
  name: Yup.string().required('Это обязательное поле'),
  amount: Yup.number().required('Это обязательное поле'),
})

const AddSpecification = () => {
  const { addSpec, toggleModal } = useSpecSearchContext()
  const { addProduct } = useTableContext()

  const [openFileSelector, { filesContent, loading, clear }] = useFilePicker({
    readAs: 'ArrayBuffer',
    accept: ['.xlsx', '.xls', '.ods'],
    multiple: false,
  })

  const [data, itemDataColumns, allColumnsWithDesc, changeDataColumn] = useTableParser(filesContent)

  const handleHeadColumnChange = useCallback(
    (key, column) => {
      changeDataColumn(key, column)
    },
    [changeDataColumn]
  )

  const handleBackButton = useCallback(() => {
    clear()
  }, [clear])

  const handleStart = useCallback(() => {
    addSpec(data.slice(1))
  }, [data, addSpec])

  const handleAddSubmit = useCallback(
    (data) => {
      toggleModal(false)
      data.unit = 'шт'
      data.exact_price = true
      data.price = 0
      addProduct(data, data.amount * 100, 0)
    },
    [toggleModal, addProduct]
  )

  if (filesContent.length === 0) {
    return (
      <Wrapper>
        <div className="under-search">
          <div className="spec-dialog">
            <h3 className="mb-3">
              <strong>Загрузка спецификации</strong>
            </h3>
            <h3 className="mb-4">
              Спецификация должна содержать следующие столбцы: <br /> <strong>артикул, наименование, кол-во</strong>
            </h3>

            <div className="btn btn_upload" onClick={openFileSelector}>
              Выбрать файл
            </div>
          </div>
          <div className="splitter">или</div>
          <div className="add-dialog">
            <h3 className="mb-3">
              <strong>Добавить вручную</strong>
            </h3>

            <Formik
              validationSchema={ItemSchema}
              onSubmit={handleAddSubmit}
              initialValues={{
                code: '',
                name: '',
                amount: 0,
              }}
            >
              {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
                <Form onSubmit={handleSubmit} className="add-form">
                  <Form.Group className="mb-3" controlId="code">
                    <Form.Label>Артикул</Form.Label>
                    <Form.Control
                      required
                      placeholder="Введите артикул"
                      name="code"
                      value={values.code}
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                      isValid={touched.code && !errors.code}
                      isInvalid={!!errors.code && touched.code}
                      feedback={errors.code}
                    />
                    <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.code}</Form.Control.Feedback>
                  </Form.Group>{' '}
                  <Form.Group className="mb-3" controlId="code">
                    <Form.Label>Название</Form.Label>
                    <Form.Control
                      required
                      placeholder="Введите название"
                      name="name"
                      value={values.name}
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                      isValid={touched.name && !errors.name}
                      isInvalid={!!errors.name && touched.name}
                      feedback={errors.name}
                    />
                    <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  </Form.Group>{' '}
                  <Form.Group className="mb-3" controlId="code">
                    <Form.Label>Количество</Form.Label>
                    <Form.Control
                      required
                      placeholder="Введите Количество"
                      name="amount"
                      value={values.amount}
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                      isValid={touched.amount && !errors.amount}
                      isInvalid={!!errors.amount && touched.amount}
                      feedback={errors.amount}
                    />
                    <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                  </Form.Group>
                  <button className="btn w-100" type="submit">
                    Добавить
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Wrapper>
    )
  }

  if (loading) {
    return <div>...loading</div>
  }

  return (
    <Wrapper>
      <h3 className="mb-4">
        <span className="btn_back" onClick={handleBackButton}>
          ← Добавить позиции
        </span>{' '}
        / <strong>Указать ключевые столбцы</strong>
      </h3>
      <div className="table-shadow">
        <Table striped bordered hover>
          <thead>
            <tr>
              {Object.entries(itemDataColumns).map(([key, column]) => (
                <th key={key} data-key={key}>
                  <NavDropdown title={(data[0] && data[0][key]) || column || 'Выбрать колонку'}>
                    {allColumnsWithDesc.map((item) => (
                      <NavDropdown.Item
                        key={item.column}
                        onClick={handleHeadColumnChange.bind(null, key, item.column)}
                        disabled={Object.values(itemDataColumns).includes(item.column)}
                      >
                        <strong>{item.column}: </strong>
                        {item.desc}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                </th>
              ))}
            </tr>
          </thead>
          {data.length > 1 && (
            <tbody>
              {data.slice(1, 7).map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, index) => (
                    <td key={index}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </div>

      <h3 className="mt-4">
        Найдено позиций: <strong>{data.length < 1 ? 0 : data.length - 1}</strong>
      </h3>
      <button className="btn btn_start" disabled={data.length < 2} onClick={handleStart}>
        Начать поиск
      </button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: block;
  padding: 1rem 2rem;
  position: relative;

  .add-form {
    min-width: 500px;
  }

  .under-search {
    display: flex;
    justify-content: space-around;
    position: relative;
    height: 100%;
  }

  .search {
    padding-bottom: 3rem;
  }

  .splitter {
    align-self: center;
    width: 50px;
    text-align: center;
    font-weight: bold;
  }

  h2 {
    margin: 0;
  }

  .btn {
    &_back {
      cursor: pointer;
    }

    &_upload {
      max-width: 400px;
    }

    &_start {
      max-width: 400px;
      margin: 0 auto;
      margin-top: 2rem;
      width: 100%;
    }
  }

  .nav-item.dropdown {
    margin-left: -1rem;
  }

  strong {
    font-weight: 500;
  }

  table {
    position: relative;

    min-height: 200px;

    th[data-key]::before {
      content: attr(data-key);
    }
  }

  .table-shadow {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      box-shadow: inset 0px -100px 65px -64px #ffffff;
      pointer-events: none;
    }
  }
`

export default AddSpecification
