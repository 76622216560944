import axios from 'axios'

const baseURL = process.env.REACT_APP_API

const $server = axios.create({
  withCredentials: true,
  baseURL,
})

$server.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
  return config
})

$server.interceptors.response.use(
  (config) => {
    return config
  },
  async (error) => {
    if (!error.config) {
      throw error
    }

    if (error.config._isRetry) {
      throw error
    }

    if (error.response.status !== 401) {
      throw error
    }

    const originalRequest = error.config
    originalRequest._isRetry = true

    try {
      const { data: auth } = await axios.get(`${baseURL}/user/refresh`, {
        withCredentials: true,
      })

      localStorage.setItem('accessToken', auth.accessToken)

      return $server.request(originalRequest)
    } catch (e) {
      console.log('Unauthorized')
    }

    throw error
  }
)

export default $server
