import $server from '.'

export const getProductImg = async (id) => {
  return await $server
    .get('product/getImgById', {
      params: {
        id,
      },
    })
    .then((res) => res.data.productImg)
}

export default getProductImg
