import React, { useState } from 'react'
import styled from 'styled-components'
import { useAuthContext } from '../../context/auth_context'
import { Row, Form, Col, Toast, ToastContainer } from 'react-bootstrap'
import * as Yup from 'yup'
import { Formik } from 'formik'

const changePasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(5, 'Минимум 5 символов')
    .max(32, 'Максимум 32 символа')
    .required('Это обязательное поле'),
  newPasswordRepeat: Yup.string()
    .test('passwords-match', 'Пароли должны совпадать', function (value) {
      return this.parent.newPassword === value
    })
    .min(5, 'Минимум 5 символов')
    .max(32, 'Максимум 32 символа')
    .required('Это обязательное поле'),
})

const Security = () => {
  const { auth, changePassword } = useAuthContext()
  const [showToast, setShowToast] = useState(false)

  const handleClose = () => {
    setShowToast(false)
  }

  const handleOpen = () => {
    setShowToast(true)
  }

  const handleSubmit = (values, formikBag) => {
    changePassword(values.newPassword)
    formikBag.resetForm()
    handleOpen()
  }

  return (
    <Wrapper>
      <ToastContainer position="top-start" className="p-3">
        <Toast show={showToast} delay={500} onClose={handleClose}>
          <Toast.Header>
            <strong className="me-auto">Безопасность</strong>
          </Toast.Header>
          <Toast.Body>Пароль изменён</Toast.Body>
        </Toast>
      </ToastContainer>
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="email">
            <Form.Label>
              Ваш email адрес{' '}
              <span className={`emailStatus ${auth.user.isActivated ? 'emailStatus_active' : 'emailStatus_unactive'}`}>
                ({auth.user.isActivated ? 'подтвержден' : 'не подтвержден'})
              </span>
            </Form.Label>
            <Form.Control type="text" placeholder="Введите ваш эмайл" value={auth.user.email} disabled />
          </Form.Group>
        </Row>
      </Form>
      <Formik
        validationSchema={changePasswordSchema}
        onSubmit={handleSubmit}
        initialValues={{
          newPassword: '',
          newPasswordRepeat: '',
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="password">
                <Form.Label>Новый пароль</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Введите новый пароль"
                  name="newPassword"
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.newPassword && !errors.newPassword}
                  isInvalid={!!errors.newPassword && touched.newPassword}
                  feedback={errors.newPassword}
                />
                <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="passwordRepeat">
                <Form.Label>Повторите пароль</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Повторите новый пароль"
                  name="newPasswordRepeat"
                  value={values.newPasswordRepeat}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.newPasswordRepeat && !errors.newPasswordRepeat}
                  isInvalid={!!errors.newPasswordRepeat && touched.newPasswordRepeat}
                  feedback={errors.newPasswordRepeat}
                />
                <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.newPasswordRepeat}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mt-5">
              <Form.Group as={Col}>
                <button className="btn" type="submit">
                  Сохранить
                </button>
              </Form.Group>
            </Row>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: block;

  .emailStatus {
    font-size: 13px;

    &_active {
      color: var(--bs-green);
    }

    &_unactive {
      color: #e43024;
    }
  }
`

export default Security
