import React from 'react'
import styled from 'styled-components'

const Settings = () => {
  return <Wrapper>Настройки</Wrapper>
}
const Wrapper = styled.div`
  display: block;
`

export default Settings
