import React, { useState } from 'react'
import styled from 'styled-components'
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useTableContext } from '../../context/table_context'
import DeleteButton from './DeleteButton'

const OneTable = ({ name, updated_at, id }) => {
  const navigate = useNavigate()
  const { load, deleteTable } = useTableContext()
  const [deleted, setDeleted] = useState(false)
  const [hover, setHover] = useState(false)

  const handleDelete = () => {
    deleteTable(id)
    setDeleted(true)
  }

  const handleMouseEnter = () => {
    setHover(true)
  }

  const handleMouseLeave = () => {
    setHover(false)
  }

  const handleClick = () => {
    navigate('/search')
    load(id)
  }

  return (
    <Wrapper
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={[hover && 'hover', 'rounded']}
      hidden={deleted}
    >
      <div className="item py-1 px-2">
        <div className="item__name">Имя: {name}</div>
        <div className="item__updated">
          <Form.Text>Отредактирована: {new Date(updated_at).toLocaleString()}</Form.Text>
        </div>
      </div>
      {hover && <DeleteButton onClick={handleDelete} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: block;
  position: relative;

  transform: translate(-0.5rem, -0.25rem);
  margin-bottom: 0.5rem;

  transition: all 0.25s ease;

  &.hover {
    background-color: #e4e4e4;
  }

  .item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    cursor: pointer;

    &__name {
      grid-column: 1 / 3;
    }
  }
`

export default OneTable
