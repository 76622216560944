import { useEffect, createRef } from 'react'
import useQuery from '../hooks/useQuery'

const AcsPage = () => {
  const form = createRef()
  const query = useQuery()

  useEffect(() => {
    if (form.current) {
      form.current.submit()
    }
  }, [form])

  return (
    <form name="downloadForm" action={query.AcsUrl || ''} method="POST" ref={form}>
      <input type="hidden" name="PaReq" value={query.PaReq} />
      <input type="hidden" name="MD" value={query.MD} />
      <input type="hidden" name="TermUrl" value={query.TermUrl} />
    </form>
  )
}

export default AcsPage
