import React from 'react'
import styled from 'styled-components'
import { getRole } from '../../utils/getRole'
import { useAuthContext } from '../../context/auth_context'

import { BiUser } from 'react-icons/bi'
import { Col, Form, Row } from 'react-bootstrap'

const Info = () => {
  const { auth } = useAuthContext()
  return (
    <Wrapper>
      <div className="row">
        <div className="header mb-5">
          <div className="header__photo rounded-circle">
            <BiUser />
          </div>
          <div className="header__text px-5">
            <h3>
              <strong>
                {auth.user.secondName} {auth.user.firstName[0]}. {auth.user.middleName[0]}.
              </strong>
            </h3>
            <div className="text">{getRole(auth.user.role)}</div>
          </div>
        </div>

        <Form>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="firstName">
              <Form.Label>Имя</Form.Label>
              <Form.Control type="text" placeholder="Введите имя" value={auth.user.firstName} disabled />
            </Form.Group>
            <Form.Group as={Col} controlId="secondName">
              <Form.Label>Фамилия</Form.Label>
              <Form.Control type="text" placeholder="Введите фамилию" value={auth.user.secondName} disabled />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="middleName">
              <Form.Label>Отчество</Form.Label>
              <Form.Control type="text" placeholder="Введите отчество" value={auth.user.middleName} disabled />
            </Form.Group>
          </Row>
          <Row className="mb-3" hidden>
            <Form.Group as={Col} controlId="org">
              <Form.Label>Организация</Form.Label>
              <Form.Control type="text" placeholder="Введите название вашей организации" disabled />
            </Form.Group>
          </Row>

          <Row className="mt-5">
            <Form.Group as={Col}>
              <div className="btn">Сохранить</div>
            </Form.Group>
          </Row>
        </Form>
      </div>
    </Wrapper>
  )
}

// <h3>
// Роль: <strong>{getRole(auth.user.role)}</strong>
// </h3>
// <br />
// <h3>Имя: {auth.user.firstName}</h3>
// <h3>Фамилия: {auth.user.secondName}</h3>
// <h3>Отчество: {auth.user.middleName}</h3>
// <br />
// <br />
// <h3>
// Email: {auth.user.email}{' '}
// <span className={`emailStatus ${auth.user.isActivated ? 'emailStatus_active' : 'emailStatus_unactive'}`}>
//   {auth.user.isActivated ? '(активирован)' : '(не активирован)'}
// </span>
// </h3>

const Wrapper = styled.div`
  display: block;

  .header {
    display: flex;
    align-items: center;

    &__photo {
      font-size: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 85px;
      height: 85px;
      border: 2px solid #dee2e6;
    }
  }

  h3 {
    strong {
      font-weight: 500;
    }
  }
`

export default Info
